import React from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { NichesStore } from '../stores/Data/Niches';
import ProductViewer from '../components/ProductViewer';
import Row from '../components/Row';
import useScrollToTop from '../hooks/useScrollToTop';
import Header from '../components/Header';
import PageStyle from '../components/PageStyle';
// import { HeaderStore } from '../stores/App/HeaderStore';

interface URLParams {
  id: string;
}

const Niche: React.FC = () => {
  // const { setIsDisabled } = HeaderStore.useContainer();
  // setIsDisabled(true); // disable sicky header

  useScrollToTop();

  const { niches, isLoading } = NichesStore.useContainer();
  const { id } = useParams<URLParams>();
  const idx = Number(id) - 1;

  if (!isLoading && (idx < 0 || idx >= niches.length)) {
    return <Redirect to="/niches/" />;
  }

  const niche = niches[idx];

  return (
    <>
      <Header title={'Niche'} />
      <PageStyle style={{ paddingTop: '2rem' }}>
        {!isLoading && niche && (
          <>
            <Row>
              <h5>Average Sales Rank</h5>
              <h5>{niche.averageSalesRank.toLocaleString()}</h5>
            </Row>
            <Row
              style={{
                paddingTop: '0.25rem',
              }}
            >
              <h5>Best Sales Rank</h5>
              <h5>{niche.bestSalesRank.toLocaleString()}</h5>
            </Row>
            <Row
              style={{
                paddingTop: '0.25rem',
              }}
            >
              <h5>Percent Sold</h5>
              <h5>{Math.round(niche.percentSalesRanks * 100)}%</h5>
            </Row>
            <ProductViewer products={niche?.listings || []} />
          </>
        )}
      </PageStyle>
    </>
  );
};

export default Niche;
