import { env } from '../../../utils/env';
import { get } from '../../../services/api';

const ENDPOINT = env('REACT_APP_API_ENDPOINT');

export const getTimer = (opts?: PromiseOptions): Promise<Timer> =>
  new Promise((resolve, reject) => {
    const url = `${ENDPOINT}${env('REACT_APP_ENDPOINT_GET_TIMER')}`;
    get<Timer>({ url }, opts)
      .then(resolve)
      .catch((error) => reject(error.messgae));
  });

export const testTimer = (opts?: PromiseOptions): Promise<Timer> =>
  new Promise((resolve, reject) => {
    console.log('fetching TEST timer');
    opts?.signal.addEventListener('abort', () => reject);
    setTimeout(() => {
      console.log('fetched TEST timer');
      resolve({
        lastUpdate: Math.floor(Date.now() / 1000) - 60,
        nextUpdate: Math.floor(Date.now() / 1000) + 5,
      });
    }, 500);
  });
