import React from 'react';
import {
  RouteProps,
  Redirect,
  Route,
  RouteComponentProps,
} from 'react-router-dom';
import { UserStore } from '../stores/User';

const PaidRoute: React.SFC<RouteProps> = ({
  component: Component,
  ...rest
}) => {
  const { getHeaders } = UserStore.useContainer();
  const headers = getHeaders();
  const render: React.FC<RouteComponentProps> = (props) =>
    Component && headers.token ? (
      <Component {...props} />
    ) : (
      <Redirect to="/settings/" />
    );

  return <Route {...rest} render={render} />;
};

export default PaidRoute;
