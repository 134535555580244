import qs from 'query-string';
import { oauthLogin } from '../core';
import {
  base64URLEncode,
  utf8ToWordArr,
  randomString,
  sha256,
} from '../../../utils/cryptography';
import { env } from '../../../utils/env';
import { CognitoUser } from 'amazon-cognito-identity-js';

type Provider = 'COGNITO' | 'Google' | 'Facebook' | 'LoginWithAmazon';

const BASE_URL = `${window.location.protocol}//${window.location.host}`;

const OAUTH_SCOPE = [
  'phone',
  'email',
  'profile',
  'openid',
  'aws.cognito.signin.user.admin',
];

const COGNITO_DOMAIN = env('REACT_APP_COGNITO_DOMAIN');

const CLIENT_ID = env('REACT_APP_CLIENT_ID');

/**
 * Creates random base 64 encoded state for oauth.
 */
const generateState = () => base64URLEncode(utf8ToWordArr(randomString(32)));

/**
 * Creates random base 64 encoded verfier for oauth.
 */
const generateCodeVerifier = () =>
  base64URLEncode(utf8ToWordArr(randomString(128)));

const generateCodeChallenge = (s: string) => base64URLEncode(sha256(s));

const setOauthState = (state: string) =>
  window.sessionStorage.setItem('oauthState', state);

const getOauthState = () =>
  window.sessionStorage.getItem('oauthState') || undefined;

const removeOauthState = () => window.sessionStorage.removeItem('oauthState');

const setPCKE = (pcke: string) =>
  window.sessionStorage.setItem('oauthPKCE', pcke);

const getPCKE = () => window.sessionStorage.getItem('oauthPKCE') || undefined;

const removePCKE = () => window.sessionStorage.removeItem('oauthPKCE');

/**
 * Detect if Oauth login has started
 */
export const isOauthStarted = () => Boolean(getPCKE());

/**
 * Begin the aouth flow for a specific provider
 * @param provider - name of oauth provider.
 */
export const initiateOauth = async (provider: Provider = 'COGNITO') => {
  const responseType = 'code';
  const state = generateState();
  setOauthState(state);
  const pkce = generateCodeVerifier();
  setPCKE(pkce);
  const codeChallenge = await generateCodeChallenge(pkce);
  const codeChallengeMethod = 'S256';
  const queryString = Object.entries({
    redirect_uri: BASE_URL, // eslint-disable-line
    response_type: responseType, // eslint-disable-line
    client_id: CLIENT_ID, // eslint-disable-line
    identity_provider: provider, // eslint-disable-line
    scopes: JSON.stringify(OAUTH_SCOPE), // eslint-disable-line
    state, // eslint-disable-line
    code_challenge_method: codeChallengeMethod, // eslint-disable-line
    code_challenge: codeChallenge, // eslint-disable-line
  })
    .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
    .join('&');

  const authUrl = `https://${COGNITO_DOMAIN}/oauth2/authorize?${queryString}`;
  window.open(authUrl, '_self');
};

/**
 * Fetch the access token from oauth provider
 * @param code - code from first aouth request
 * @param opts - optional signal
 */
export const completeOauth = async (
  opts?: PromiseOptions
): Promise<CognitoUser> =>
  new Promise((resolve, reject) => {
    if (opts?.signal) opts.signal.addEventListener('abort', reject);
    const { code, state, error, error_description: desc } = qs.parse(
      window.location.search
    );
    if (error) reject(desc);
    if (getOauthState() !== state) reject('Invalid state.');
    const body = Object.entries({
      grant_type: 'authorization_code', // eslint-disable-line
      code: code as string,
      client_id: CLIENT_ID, // eslint-disable-line
      redirect_uri: BASE_URL, // eslint-disable-line
      code_verifier: getPCKE() || '', // eslint-disable-line
    })
      .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
      .join('&');
    const url = `https://${COGNITO_DOMAIN}/oauth2/token`;
    fetch(url, {
      signal: opts?.signal,
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body,
    })
      .then((response) => response.json())
      .then((json) => {
        const { error: tokenError, ...tokens } = json as OauthTokenResponse;
        window.history.replaceState({}, document.title, BASE_URL);
        removeOauthState();
        removePCKE();
        if (tokenError) throw Error(tokenError);
        return oauthLogin(tokens as OauthSessionParams, opts);
      })
      .then(resolve)
      .catch((error) => reject(error.message));
  });

/**
 * invalidate oauth tokens
 */
export const signoutOauth = () => {
  const queryString = Object.entries({
    logout_uri: BASE_URL, // eslint-disable-line
    client_id: CLIENT_ID, // eslint-disable-line
  })
    .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
    .join('&');
  const logoutUrl = `https://${COGNITO_DOMAIN}/logout?${queryString}`;
  window.open(logoutUrl, '_self');
};
