import React from 'react';
import './PageStyle.css';

type DivProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const PageStyle: React.FC<DivProps> = ({
  children,
  className: passClass,
  ...props
}) => {
  const className = ['pagestyle', passClass || ''].join(' ');

  return (
    <div className={className} {...props}>
      {children}
    </div>
  );
};

export default PageStyle;
