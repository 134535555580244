import { env } from '../../../utils/env';
import { get } from '../../../services/api';

export const formatRawProduct = (rawProduct: RawProduct): Product => ({
  title: rawProduct.title,
  asin: rawProduct.asin,
  trademarked: rawProduct.trademarked === 'True',
  imgUrl: rawProduct.imgUrl,
  link: rawProduct.link,
  salesRank: Number(rawProduct.salesRank),
  rank: rawProduct.rangeKey + 1,
  uploaded: new Date(Number(rawProduct.unix) * 1000),
});

export const getProductData = (
  productName: ProductName,
  start: number,
  limit: number,
  headers: Obj,
  opts?: PromiseOptions
): Promise<Product[]> =>
  new Promise((resolve, reject) => {
    const route = headers.token
      ? env('REACT_APP_ENDPOINT_GET_PAID_PRODUCTS')
      : env('REACT_APP_ENDPOINT_GET_FREE_PRODUCTS');

    const url = `${env('REACT_APP_API_ENDPOINT')}${route}`;

    const [product, category, tmStr] = productName.split('_');
    const trademarked = tmStr === 'tm' ? 'true' : 'false';
    const queryString: Obj = {
      product,
      category,
      trademarked,
      start: String(start),
      limit: String(limit),
    };

    get<RawProduct[]>({ url, queryString, headers }, opts)
      .then((rawProducts) =>
        resolve(
          rawProducts.map((p) => {
            const formatted = formatRawProduct(p);
            formatted.isPopSocket = product === 'popsockets';
            return formatted;
          })
        )
      )
      .catch(reject);
  });
