import { useState } from 'react';
import { createContainer } from 'unstated-next';
import { UserStore } from '../../User';
import { getProductData } from './api';
import { useAuthEvents } from '../../../hooks/useAuthEvents';
import { resolve } from 'dns';
import { useTimerEvents } from '../../../hooks/useTimerEvents';
// import { useTimerEvents } from '../../../hooks/useTimerEvents';

export const productNames = [
  'shirts_rising_tm',
  'shirts_rising_ntm',
  'shirts_featured_tm',
  'shirts_featured_ntm',
  'shirts_newest_ntm',
  'shirts_newest_tm',
  'popsockets_rising_ntm',
  'popsockets_rising_tm',
  'popsockets_featured_ntm',
  'popsockets_featured_tm',
];

const State = (key: ProductName | '' = '', itemsPerRequest = 30) => {
  if (!key) throw Error(`Expected ${productNames}.`);

  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPaid, setIsPaid] = useState<boolean>(false);
  const [hasAll, setHasAll] = useState<boolean>(false);
  const [products, setProducts] = useState<Product[]>([]);

  const { getHeaders } = UserStore.useContainer();

  const getNextPage = (
    oldProducts = [...products],
    opts?: PromiseOptions
  ): Promise<void> =>
    new Promise((resolve) => {
      const start = oldProducts.length;
      const limit = itemsPerRequest + 1;
      const headers = getHeaders();
      setIsLoading(true);
      getProductData(key, start, limit, headers, opts)
        .then((newProducts) => {
          if (isPaid && newProducts.length < limit) setHasAll(true);
          const spliced = newProducts.splice(0, itemsPerRequest);
          setProducts(oldProducts.concat(spliced));
          setIsLoading(false);
          resolve();
        })
        .catch((error) => setError(error.message))
        .then(() => setIsLoading(false));
    });

  const reset = (opts?: PromiseOptions): Promise<void> =>
    new Promise((resolve, reject) => {
      setHasAll(false);
      setProducts([]);
      getNextPage([], opts).then(resolve).catch(reject);
    });

  const onUpdateUser = (opts: PromiseOptions): Promise<void> =>
    new Promise((resolve, reject) => {
      opts.signal.addEventListener('abort', reject);
      const headers = getHeaders();
      if (headers.token) setIsPaid(true);
      resolve();
    });

  const onTimerEnd = (opts: PromiseOptions) => {
    !opts.signal.aborted && setHasAll(true);
    return Promise.resolve();
  };

  useAuthEvents({
    onCredentialLoaded: (opts) => getNextPage([], opts),
    onLogout: reset,
    onLogin: onUpdateUser,
    onUpdateUser,
  });

  useTimerEvents({
    onTimerEnd,
  });

  // useTimerEvents({
  //   onTimerEnd: (opts) => reset(opts),
  // });

  return {
    getNextPage,
    reset,
    isLoading,
    hasAll: hasAll || !isPaid,
    products,
    error,
    key,
  };
};

export const PopSocketsFeaturedNTM = createContainer(() =>
  State('popsockets_featured_ntm')
);

export const PopSocketsFeaturedTM = createContainer(() =>
  State('popsockets_featured_tm')
);

export const PopSocketsRisingNTM = createContainer(() =>
  State('popsockets_rising_ntm')
);

export const PopSocketsRisingTM = createContainer(() =>
  State('popsockets_rising_tm')
);

export const ShirtsFeaturedNTM = createContainer(() =>
  State('shirts_featured_ntm')
);

export const ShirtsFeaturedTM = createContainer(() =>
  State('shirts_featured_tm')
);

export const ShirtsNewestNTM = createContainer(() =>
  State('shirts_newest_ntm')
);

export const ShirtsNewestTM = createContainer(() => State('shirts_newest_tm'));

export const ShirtsRisingNTM = createContainer(() =>
  State('shirts_rising_ntm')
);

export const ShirtsRisingTM = createContainer(() => State('shirts_rising_tm'));
