import React, { useState, useEffect } from 'react';
import {
  Elements,
  useElements,
  useStripe,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import {
  loadStripe,
  Token,
  StripeCardNumberElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardCvcElementChangeEvent,
} from '@stripe/stripe-js';
import { env } from '../../utils/env';
import './PaymentForm.css';
import Callout from '../Callout';
import Loader from '../Loader';

interface Props {
  buttonTitle: string;
  onToken: (token: string) => Promise<void>;
  error: string;
}

const Form: React.FC<Props> = ({ buttonTitle, onToken, error: errorIn }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>(errorIn);
  const [errorCard, setErrorCard] = useState<string>('');
  const [errorExpiry, setErrorExpiry] = useState<string>('');
  const [errorCVC, setErrorCVC] = useState<string>('');
  const stripe = useStripe();
  const elements = useElements();

  const generateToken = (opts: PromiseOptions): Promise<Token> =>
    new Promise((resolve, reject) => {
      if (opts.signal) opts.signal.addEventListener('abort', reject);
      if (!stripe || !elements) {
        reject('No stripe of elements');
        return;
      }
      const cardElement = elements.getElement(CardNumberElement);
      if (!cardElement) {
        reject(' No card elements');
        return;
      }
      stripe
        .createToken(cardElement)
        .then(({ token, error }) => {
          if (token) resolve(token);
          else reject(error);
        })
        .catch(reject);
    });

  useEffect(() => setError(errorIn), [errorIn]);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (isLoading) {
      generateToken({ signal })
        .then(({ id }) => id)
        .then((token) => onToken(token))
        .then(() => {
          const cardEl = elements && elements.getElement(CardNumberElement);
          cardEl?.clear();
          const expElement = elements && elements.getElement(CardExpiryElement);
          expElement?.clear();
          const cvcElement = elements && elements.getElement(CardCvcElement);
          cvcElement?.clear();
        })
        .catch(({ message }) => {
          !signal.aborted && setError(message);
        })
        .then(() => {
          !signal.aborted && setIsLoading(false);
        });
    }
    return () => controller.abort();
  }, [isLoading]);

  // const generateToken = () => new Promise((resolve, reject) => {});

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
  };

  const style = {
    base: {
      fontWeight: '300',
      fontSize: '1.4rem',
      fontFamily: 'museo-sans, sans-serif',
    },
  };

  const onChangeCard = (event: StripeCardNumberElementChangeEvent) => {
    setErrorCard(event.error?.message || '');
  };

  const onChangeExpiry = (event: StripeCardExpiryElementChangeEvent) => {
    setErrorExpiry(event.error?.message || '');
  };

  const onChangeCVC = (event: StripeCardCvcElementChangeEvent) => {
    setErrorCVC(event.error?.message || '');
  };

  const errorMessage = error || errorCard || errorExpiry || errorCVC;

  return (
    <>
      {errorMessage && (
        <Callout
          status="error"
          style={{ marginTop: '1rem', marginBottom: '1rem' }}
        >
          <h4>Error:</h4>
          <p style={{ margin: 0 }}>{errorMessage}</p>
        </Callout>
      )}
      <form onSubmit={submit} style={{ marginTop: '1rem' }} className="fadeIn">
        <div className="row">
          <div className="col-xs-12">
            <label>
              Card Number
              <CardNumberElement
                className="input paymentform-element-input"
                options={{ style }}
                onChange={onChangeCard}
              />
            </label>
          </div>
        </div>
        <div className="row" style={{ marginTop: '1rem' }}>
          <div className="col-xs-6">
            <label>
              Expiration
              <CardExpiryElement
                className="input paymentform-element-input"
                options={{ style }}
                onChange={onChangeExpiry}
              />
            </label>
          </div>
          <div className="col-xs-6">
            <label>
              CVC
              <CardCvcElement
                className="input paymentform-element-input"
                options={{ style }}
                onChange={onChangeCVC}
              />
            </label>
          </div>
        </div>
        <button
          type="submit"
          disabled={isLoading || Boolean(errorMessage)}
          className="button-wide"
          style={{ marginTop: '1.75rem' }}
        >
          {isLoading ? <Loader /> : <span>{buttonTitle}</span>}
        </button>
      </form>
    </>
  );
};

const STRIPE_KEY = env('REACT_APP_STRIPE_PK');

const Wrapper: React.FC = ({ children }) => (
  <Elements
    stripe={loadStripe(STRIPE_KEY)}
    options={{
      fonts: [
        {
          family: 'museo-sans',
          cssSrc: 'https://use.typekit.net/rva4lfj.css',
          weight: '300',
        },
      ],
    }}
  >
    {children}
  </Elements>
);

const PaymentForm: React.FC<Props> = (props) => (
  <Wrapper>
    <Form {...props} />
  </Wrapper>
);

export default PaymentForm;
