import React from 'react';
import './Callout.css';

type DivProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

export interface CalloutProps extends DivProps {
  status: 'primary' | 'info' | 'warning' | 'error' | 'success';
}

const Callout: React.FC<CalloutProps> = ({
  status,
  className,
  children,
  ...props
}) => {
  const classList = ['callout', `callout-${status}`, className || ''];
  return (
    <div className={classList.join(' ')} {...props}>
      {children}
    </div>
  );
};

export default Callout;
