import {
  CognitoUserSession,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoAccessToken,
  CognitoUser,
  CognitoUserPool,
  AuthenticationDetails,
} from 'amazon-cognito-identity-js';
import { Credentials } from '../federation';
import { env } from '../../../utils/env';
import { signoutOauth } from '../oauth';

const USERPOOL_ID = env('REACT_APP_USERPOOL_ID');
const CLIENT_ID = env('REACT_APP_CLIENT_ID');

const setOauthComplete = () =>
  window.localStorage.setItem('oauthComplete', 'true');

const removeOauthComplete = () =>
  window.localStorage.removeItem('oauthComplete');

const getOauthComplete = () =>
  window.localStorage.getItem('oauthComplete') || undefined;

export const isOauth = () => Boolean(getOauthComplete());

export const UserPool = new CognitoUserPool({
  UserPoolId: USERPOOL_ID,
  ClientId: CLIENT_ID,
});

export const createSession = (params: OauthSessionParams) =>
  new CognitoUserSession({
    IdToken: new CognitoIdToken({ IdToken: params.id_token }),
    RefreshToken: new CognitoRefreshToken({
      RefreshToken: params.refresh_token,
    }),
    AccessToken: new CognitoAccessToken({
      AccessToken: params.access_token,
    }),
  });

export const createCognitoUser = (Username: string) =>
  new CognitoUser({
    Username,
    Pool: UserPool,
  });

export const login = (
  Username: string,
  Password: string,
  opts?: PromiseOptions
): Promise<CognitoUser> =>
  new Promise((resolve, reject) => {
    if (opts?.signal) opts.signal.addEventListener('abort', reject);
    const authDetails = new AuthenticationDetails({
      Username,
      Password,
    });
    const user = createCognitoUser(authDetails.getUsername());
    user.authenticateUser(authDetails, {
      onSuccess: (session: CognitoUserSession) => {
        Credentials.initialize(session);
        resolve(user);
      },
      onFailure: reject,
      newPasswordRequired: () =>
        reject({ message: 'Please contact customer support.' }),
    });
  });

export const oauthLogin = (
  tokens: OauthSessionParams,
  opts?: PromiseOptions
): Promise<CognitoUser> =>
  new Promise((resolve, reject) => {
    if (opts?.signal) opts.signal.addEventListener('abort', reject);
    try {
      const session = createSession(tokens);
      const userName = session.getIdToken().decodePayload()['cognito:username'];
      const user = createCognitoUser(userName);
      user.setSignInUserSession(session);
      Credentials.initialize(session);
      setOauthComplete();
      resolve(user);
    } catch (error) {
      reject(error.message);
    }
  });

export const logout = (opts?: PromiseOptions): Promise<void> =>
  new Promise((resolve, reject) => {
    if (opts?.signal) opts.signal.addEventListener('abort', reject);
    try {
      const user = UserPool.getCurrentUser();
      if (!user) return;
      Credentials.clear();
      user.signOut();
      if (isOauth()) {
        console.log('oauth logout');
        removeOauthComplete();
        signoutOauth();
      }
      resolve();
    } catch (error) {
      reject(error.message);
    }
  });

export const getUserSession = (
  user: CognitoUser,
  opts?: PromiseOptions
): Promise<CognitoUserSession> =>
  new Promise((resolve, reject) => {
    if (opts?.signal) opts.signal.addEventListener('abort', reject);
    user.getSession((err: string, session: CognitoUserSession) => {
      if (err) reject(err);
      else resolve(session);
    });
  });

export const forgotPassword = (user: CognitoUser, opts?: PromiseOptions) =>
  new Promise((resolve, reject) => {
    if (opts?.signal) opts.signal.addEventListener('abort', reject);
    user.forgotPassword({ onSuccess: resolve, onFailure: reject });
  });

export const forgotPasswordSubmit = (
  user: CognitoUser,
  code: string,
  password: string,
  opts?: PromiseOptions
): Promise<void> =>
  new Promise((resolve, reject) => {
    if (opts?.signal) opts.signal.addEventListener('abort', reject);
    user.confirmPassword(code, password, {
      onSuccess: resolve,
      onFailure: reject,
    });
  });

export const changePassword = (
  user: CognitoUser,
  oldPassword: string,
  newPassword: string,
  opts?: PromiseOptions
): Promise<void> =>
  new Promise((resolve, reject) => {
    if (opts?.signal) opts.signal.addEventListener('abort', reject);
    user.changePassword(oldPassword, newPassword, (err) => {
      if (err) reject(err);
      else resolve();
    });
  });

export const resendEmailVerfication = (
  user: CognitoUser,
  opts?: PromiseOptions
) =>
  new Promise((resolve, reject) => {
    if (opts?.signal) opts.signal.addEventListener('abort', reject);
    user.getAttributeVerificationCode('email', {
      onFailure: (err) => reject(err),
      onSuccess: resolve,
    });
  });

export const updateUserAttributes = (
  user: CognitoUser,
  attributes: { [key: string]: string },
  opts?: PromiseOptions
) =>
  new Promise((resolve, reject) => {
    if (opts?.signal) opts.signal.addEventListener('abort', reject);
    const formattedAttributes = Object.entries(
      attributes
    ).map(([Name, Value]) => ({ Name, Value }));
    user.updateAttributes(formattedAttributes, (err) => {
      if (err) reject(err);
      else resolve();
    });
  });

export const verifyEmail = (
  user: CognitoUser,
  code: string,
  opts?: PromiseOptions
) =>
  new Promise((resolve, reject) => {
    if (opts?.signal) opts.signal.addEventListener('abort', reject);
    user.verifyAttribute('email', code, {
      onSuccess: resolve,
      onFailure: reject,
    });
  });

export const refreshSession = (
  user: CognitoUser,
  refreshToken: CognitoRefreshToken,
  opts?: PromiseOptions
): Promise<CognitoUserSession> =>
  new Promise((resolve, reject) => {
    if (opts?.signal) opts.signal.addEventListener('abort', reject);
    user.refreshSession(refreshToken, (err, session) => {
      if (err) reject(err);
      else resolve(session);
    });
  });

export const updateUser = (opts?: PromiseOptions): Promise<CognitoUser> =>
  new Promise((resolve, reject) => {
    if (opts?.signal) opts.signal.addEventListener('abort', reject);
    const user = UserPool.getCurrentUser();
    if (user)
      getUserSession(user, opts)
        .then((session) => {
          const refreshToken = session.getRefreshToken();
          return refreshSession(user, refreshToken, opts);
        })
        .then(Credentials.initialize)
        .then(() => resolve(user))
        .catch(reject);
    else reject('No user logged in.');
  });

export const getCognitoUserData = (
  user: CognitoUser,
  opts?: PromiseOptions
): Promise<CognitoUserData> =>
  new Promise((resolve, reject) => {
    if (opts?.signal) opts.signal.addEventListener('abort', reject);
    //eslint-disable-next-line
    (user.getUserData as any)(
      //eslint-disable-next-line
      (err: any, data: any) => {
        if (err) reject(err);
        else if (data) {
          const cleaned = {
            username: data.Username,
            ...data.UserAttributes.reduce(
              //eslint-disable-next-line
              (acc: any, att: any) => ({ ...acc, [att.Name]: att.Value }),
              {}
            ),
          };
          resolve(cleaned as CognitoUserData);
        } else reject('No user data');
      },
      { bypassCache: true }
    );
  });

if (env('REACT_APP_STAGE') === 'DEVELOPMENT') {
  (window as any).login = login; //eslint-disable-line
  (window as any).logout = logout; //eslint-disable-line
}
