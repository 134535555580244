import React, { useState } from 'react';
import Stripe from '@stripe/stripe-js';
import Header from '../../components/Header';
import PageStyle from '../../components/PageStyle';
import { UserStore } from '../../stores/User';
import { Redirect } from 'react-router-dom';
import Callout from '../../components/Callout';
import PaymentForm from '../../components/PaymentForm';
import { updatePayment } from './api';

const AddPayment: React.FC = () => (
  <Callout status="warning" style={{ marginTop: '1rem' }}>
    <h4>Please Enter Payment Method</h4>
    <p style={{ margin: 0 }}>
      You currently do not have an active payment method.
    </p>
  </Callout>
);

const PaymentInfo: React.FC<Stripe.Card> = ({
  brand,
  last4,
  exp_month: expMonth,
  exp_year: expYear,
}) => (
  <Callout status="info" style={{ marginTop: '1rem' }}>
    <h4>Current Payment Method</h4>
    <p style={{ margin: 0 }}>
      {brand} ending in {last4}. Expires on {`0${expMonth}`.slice(-2)}/{expYear}
      .
    </p>
  </Callout>
);

const Success: React.FC<Stripe.Card> = ({
  brand,
  last4,
  exp_month: expMonth,
  exp_year: expYear,
}) => (
  <Callout status="success" style={{ marginTop: '1rem' }}>
    <h4>Added Payment Method</h4>
    <p style={{ margin: 0 }}>
      Successfully added {brand} ending in {last4}. Expires on{' '}
      {`0${expMonth}`.slice(-2)}/{expYear}.
    </p>
  </Callout>
);

const Payment: React.FC = () => {
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);

  const { customData, updateUser } = UserStore.useContainer();
  if (!customData) return <Redirect to="/settings/" />;
  const { customer } = customData;
  const { sources, id: customerID } = customer;
  const { data } = sources;
  const card: Stripe.Card | undefined = (data || [])[0];

  const onTokenSuccess = (token: string): Promise<void> =>
    new Promise((resolve, reject) => {
      updatePayment({ token, customerID })
        .then(() => updateUser())
        .then(() => setSuccess(true))
        .then(() => resolve())
        .catch((error) => {
          if (error === '__contact__')
            setError(
              'Something went wrong please contact the Merch Data team.'
            );
          setSuccess(false);
          reject();
        });
    });

  return (
    <>
      <Header title="Payment" />
      <PageStyle>
        {card && success && <Success {...card} />}
        {card && !success && <PaymentInfo {...card} />}
        {!card && !success && <AddPayment />}
        <PaymentForm
          onToken={onTokenSuccess}
          buttonTitle={card ? 'Update Payment' : 'Add Payment'}
          error={error}
        />
      </PageStyle>
    </>
  );
};

export default Payment;
