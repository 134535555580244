import React, { useState } from 'react';
import { Redirect, Link, useHistory } from 'react-router-dom';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { useFormik, FormikErrors } from 'formik';
import { UserStore } from '../stores/User';
import Header from '../components/Header';
import PageStyle from '../components/PageStyle';
import Callout from '../components/Callout';
import { changePassword, updateUser } from '../services/cognito/core';
import Loader from '../components/Loader';

interface ChangePasswordFields {
  oldPassword: string;
  newPassword: string;
}

const ChangePassword = () => {
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const { isOauth, userData, logout, cognitoUser } = UserStore.useContainer();
  const { push } = useHistory();
  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
    },
    validate: (values) => {
      const e: FormikErrors<ChangePasswordFields> = {};
      const { newPassword, oldPassword } = values;
      setError('');

      if (!oldPassword) {
        e.oldPassword = 'Please enter your old password.';
      }
      if (!newPassword) {
        e.newPassword = 'A new password is required.';
      } else if (newPassword.length < 6) {
        e.newPassword =
          'Your new password must be more than six characters long.';
      }

      if (newPassword !== '' || oldPassword !== '') setIsSuccess(false);
      return e;
    },
    validateOnChange: true,
    onSubmit: (values, actions) => {
      const { oldPassword, newPassword } = values;
      const user = cognitoUser as CognitoUser;
      setIsLoading(true);
      setError('');
      changePassword(user, oldPassword, newPassword)
        .then(() => updateUser())
        .then(() => {
          setIsSuccess(true);
          setIsLoading(false);
          actions.resetForm();
        })
        .catch((error) => {
          console.log(error);
          setError(error.message);
          setIsLoading(false);
        });
    },
  });

  if (isOauth()) {
    return <Redirect to="/settings/" />;
  }

  const { oldPassword, newPassword } = formik.values;
  const { errors } = formik;

  const errorMessage = Object.values(errors)[0] || error || '';

  const onReset = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    logout().then(() => push('/auth/password/reset/start/'));
  };

  return (
    <>
      <Header title="Change Password" />
      <PageStyle>
        <Callout status="info" style={{ margin: '1rem 0' }}>
          <h4>Changing your password</h4>
          <p>
            To change your password, please enter your old password. If you do
            not remember your old password,{' '}
            <Link to="/auth/password/reset/start/" onClick={onReset}>
              click here
            </Link>{' '}
            to reset your password.
          </p>
        </Callout>
        {errorMessage && (
          <Callout status="error" style={{ marginBottom: '1rem' }}>
            <h4>Error</h4>
            <p>{errorMessage}</p>
          </Callout>
        )}
        {isSuccess && (
          <Callout status="success" style={{ marginBottom: '1rem' }}>
            <h4>Success</h4>
            <p>You have successfully changed your password.</p>
          </Callout>
        )}
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <input
            hidden
            value={userData?.email}
            readOnly
            name="email"
            type="email"
          />
          <label>
            Old Password
            <input
              name="oldPassword"
              id="oldPassword"
              type="password"
              onChange={formik.handleChange}
              value={oldPassword}
              placeholder="Old Password"
              style={{ marginBottom: '1rem' }}
            />
          </label>
          <label>
            New Password
            <input
              name="newPassword"
              id="newPassword"
              type="password"
              autoComplete="new-password"
              onChange={formik.handleChange}
              value={newPassword}
              placeholder="New Password"
              style={{ marginBottom: '1rem' }}
            />
          </label>
          <button type="submit" className="button-wide">
            {isLoading ? <Loader /> : <span>Complete Reset</span>}
          </button>
        </form>
      </PageStyle>
    </>
  );
};

export default ChangePassword;
