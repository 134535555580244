import React, { useState } from 'react';
import AuthModal from '../../components/AuthModal';
import Divider from '../../components/Divider';
import { Link, useHistory } from 'react-router-dom';
import { useFormik, FormikErrors } from 'formik';
import Row from '../../components/Row';
import Callout from '../../components/Callout';
import { UserStore } from '../../stores/User';
import { createCognitoUser, forgotPassword } from '../../services/cognito/core';
import Loader from '../../components/Loader';
import { IAuthenticationDetailsData } from 'amazon-cognito-identity-js';

interface LoginFields {
  email: string;
  password: string;
}

const LoginEmail: React.FC = () => {
  const { login } = UserStore.useContainer();
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { push } = useHistory();
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validate: (values) => {
      const e: FormikErrors<LoginFields> = {};
      const { email, password } = values;
      setError('');
      if (!email) {
        e.email = 'An email is required';
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        e.email = 'Invalid email address';
      }

      if (!password) {
        e.password = 'A password is required';
      }
      return e;
    },
    onSubmit: (values) => {
      const { email, password } = values;
      setError('');
      setIsLoading(true);
      login(email, password).catch((error) => {
        if (error.code === 'PasswordResetRequiredException') {
          const user = createCognitoUser(email);
          forgotPassword(user)
            .then(() =>
              push({
                pathname: '/auth/password/reset/confirm/',
                state: {
                  email,
                  message:
                    'Your password has been reset. A verification code was sent to your email please enter it below with your new password.',
                },
              })
            )
            .catch((errorReset) => {
              setError(errorReset.message);
              setIsLoading(false);
            });
        } else {
          setError(error.message);
          setIsLoading(false);
        }
      });
    },
  });

  const { email, password } = formik.values;
  const { errors } = formik;

  const errorMessage = Object.values(errors)[0] || error || '';
  return (
    <AuthModal>
      <div
        className="row center-xs"
        style={{ margin: 0, marginBottom: '1rem' }}
      >
        <h2>Login with Email</h2>
      </div>
      {errorMessage && (
        <Callout status="error" style={{ marginBottom: '1rem' }}>
          <h4>Error</h4>
          <p>{errorMessage}</p>
        </Callout>
      )}
      <form onSubmit={formik.handleSubmit}>
        <label>
          Email
          <input
            name="email"
            id="email"
            type="email"
            onChange={formik.handleChange}
            value={email}
            placeholder="Email"
            style={{ marginBottom: '1rem' }}
          />
        </label>
        <label>
          <Row>
            <span>Password</span>
            <Link to="/auth/password/reset/start/">Forgot Password?</Link>
          </Row>
          <input
            name="password"
            id="password"
            type="password"
            onChange={formik.handleChange}
            value={password}
            placeholder="Password"
            style={{ marginBottom: '1rem' }}
          />
        </label>
        <button type="submit" className="button-wide">
          {isLoading ? <Loader /> : <span>Login</span>}
        </button>
      </form>

      <Divider />
      <div className="row center-xs">
        <p style={{ margin: '0.5rem 0' }}>
          We no longer support sign up with email.
        </p>
      </div>
      <Link
        to="/auth/login/"
        className="button button-wide"
        style={{ marginTop: '1rem' }}
      >
        Sign up with Social Provider
      </Link>
    </AuthModal>
  );
};

export default LoginEmail;
