import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import './PriceTable.css';

const PriceTable: React.FC = () => {
  return (
    <table className="pricetable">
      <tbody>
        <tr className="row middle-xs">
          <td className="col-xs-10">{`Daily updates you can rely on`}</td>
          <td className="col-xs-2">
            <FontAwesomeIcon icon={faCheck} />
          </td>
        </tr>
        <tr className="row middle-xs">
          <td className="col-xs-10">
            {`The newest, best selling, and rising shirts`}
          </td>
          <td className="col-xs-2">
            <FontAwesomeIcon icon={faCheck} />
          </td>
        </tr>
        <tr className="row middle-xs">
          <td className="col-xs-10">{`The best selling and rising popsockets`}</td>
          <td className="col-xs-2">
            <FontAwesomeIcon icon={faCheck} />
          </td>
        </tr>
        <tr className="row middle-xs">
          <td className="col-xs-10">{`The hottest shirt niches`}</td>
          <td className="col-xs-2">
            <FontAwesomeIcon icon={faCheck} />
          </td>
        </tr>
        <tr className="row middle-xs">
          <td className="col-xs-10">{`Comprehensive niche analysis`}</td>
          <td className="col-xs-2">
            <FontAwesomeIcon icon={faCheck} />
          </td>
        </tr>
        <tr className="row middle-xs">
          <td className="col-xs-10">{`Trademark filter`}</td>
          <td className="col-xs-2">
            <FontAwesomeIcon icon={faCheck} />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default PriceTable;
