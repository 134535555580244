import { useState, useEffect } from 'react';
import { createContainer } from 'unstated-next';
import { UserStore } from '../User';

const State = () => {
  const { userData } = UserStore.useContainer();
  const [isDocked, setIsDocked] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    const media = window.matchMedia(`(min-width: 750px)`);
    setIsDocked(media.matches);
    const listener = () => {
      setIsDocked(media.matches);
      setIsOpen(false);
    };
    media.addListener(listener);
    return () => media.removeListener(listener);
  }, []);

  // https://stackoverflow.com/questions/16109561/force-stop-momentum-scrolling-on-iphone-ipad-in-javascript

  // useEffect(() => {
  //   if (!isDocked) {
  //     if (isOpen) {
  //       window.document.body.style.overflow = 'hidden';
  //     } else {
  //     }
  //   }
  // }, [isOpen, isDocked]);

  return {
    sidebarWidth: 200,
    isDocked: isDocked && Boolean(userData),
    isOpen,
    setIsDockOpen: setIsOpen,
  };
};

export const SidebarStore = createContainer(State);
