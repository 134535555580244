import React from 'react';
import ReactDOM from 'react-dom';

import 'normalize.css';
import 'animate.css';
import 'flexboxgrid';
import './css/index.css';
import './css/other.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { UserStore } from './stores/User';
import { TimerStore } from './stores/Data/Timer';
import { PopSocketsRisingNTM, ShirtsRisingNTM } from './stores/Data/Products';
import { NichesStore } from './stores/Data/Niches';
import App from './routes';
import { SidebarStore } from './stores/App/Sidebar';
import { DiscoverStore } from './stores/App/Discover';

import ReactPixel from 'react-facebook-pixel';
import ScrollToTop from './components/ScrollToTop';
import { env } from './utils/env';

const pixelID = env('REACT_APP_API_ENDPOINT');
ReactPixel.init(pixelID);
ReactPixel.pageView();

ReactDOM.render(
  <Router>
    <ScrollToTop />
    <UserStore.Provider>
      <SidebarStore.Provider>
        <DiscoverStore.Provider>
          <TimerStore.Provider>
            <PopSocketsRisingNTM.Provider>
              <ShirtsRisingNTM.Provider>
                <NichesStore.Provider>
                  <App />
                </NichesStore.Provider>
              </ShirtsRisingNTM.Provider>
            </PopSocketsRisingNTM.Provider>
          </TimerStore.Provider>
        </DiscoverStore.Provider>
      </SidebarStore.Provider>
    </UserStore.Provider>
  </Router>,
  document.getElementById('root')
);
