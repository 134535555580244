import React from 'react';
import ReactLoading from 'react-loading';
import './InfiniteLoader.css';

const InfiniteLoader: React.FC = () => {
  return (
    <div className="row center-xs infiniteloader ">
      <ReactLoading
        type={'spin'}
        color={'var(--color-primary)'}
        height={32}
        width={32}
      />
    </div>
  );
};
export default InfiniteLoader;
