import React from 'react';
import './AuthModal.css';

const AuthModal: React.FC = ({ children }) => {
  return (
    <div className="authmodal-container">
      <div className="authmodal-body">{children}</div>
    </div>
  );
};

export default AuthModal;
