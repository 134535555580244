import { env } from '../../utils/env';
import { post } from '../../services/api';

const ENDPOINT = env('REACT_APP_API_ENDPOINT');

export interface ContactFields {
  email: string;
  name: string;
  subject: string;
  message: string;
}

interface SendContactParams extends ContactFields {
  sub?: string;
}

export const sendContact = (
  { sub, email, name, subject, message }: SendContactParams,
  opts?: PromiseOptions
) => {
  const url = `${ENDPOINT}${env('REACT_APP_ENDPOINT_POST_CONTACT_FORM')}`;
  const body = JSON.stringify({
    sub: sub || 'No Sub',
    email,
    name,
    subject,
    message,
  });
  return post({ url, body }, opts);
};
