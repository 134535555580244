import React from 'react';
import AuthModal from '../../components/AuthModal';
import { UserStore } from '../../stores/User';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faAmazon } from '@fortawesome/free-brands-svg-icons';
import Divider from '../../components/Divider';
import { Link } from 'react-router-dom';

const FacebookButton: React.FC = () => {
  const { facebookLogin } = UserStore.useContainer();
  return (
    <button
      type="button"
      className=" row center-xs middle-xs button-social button-social-facebook"
      onClick={() => facebookLogin()}
    >
      <FontAwesomeIcon icon={faFacebookF} className="col-xs-1 start-xs" />
      <span className="col-xs center-xs">Continue with Facebook</span>
    </button>
  );
};

const AmazonButton: React.FC = () => {
  const { amazonLogin } = UserStore.useContainer();
  return (
    <button
      type="button"
      className="button-social button-social-amazon row center-xs middle-xs"
      onClick={() => amazonLogin()}
    >
      <FontAwesomeIcon
        icon={faAmazon}
        className="col-xs-1 start-xs"
        fontSize="4rem"
      />
      <span className="col-xs center-xs">Continue with Amazon</span>
    </button>
  );
};

const Login: React.FC = () => (
  <AuthModal>
    <div className="row center-xs">
      <h2>Welcome to Merch Data</h2>
    </div>
    <div className="row center-xs">
      <span>Essential data, updated daily.</span>
    </div>
    <FacebookButton />
    <AmazonButton />
    <Divider />
    <Link
      to="/auth/login/email/"
      className="button button-wide"
      style={{ marginTop: '1rem' }}
    >
      Continue with Email
    </Link>
  </AuthModal>
);

export default Login;
