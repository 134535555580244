import { useState, useEffect } from 'react';
import { createContainer } from 'unstated-next';
import { CognitoUser } from 'amazon-cognito-identity-js';
import {
  login as cognitoLogin,
  logout as cognitoLogout,
  getCognitoUserData,
  updateUser as cognitoUpdateUser,
  isOauth,
} from '../../services/cognito/core';
import {
  isOauthStarted,
  completeOauth,
  initiateOauth,
} from '../../services/cognito/oauth';
import { getCustomData } from './api';

const State = () => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | undefined>();
  const [userData, setUserData] = useState<CognitoUserData | undefined>();
  const [customData, setCustomData] = useState<CustomData | undefined>();

  const login = async (
    email: string,
    password: string,
    opts?: PromiseOptions
  ): Promise<void> =>
    new Promise((resolve, reject) => {
      cognitoLogin(email, password, opts)
        .then((user) => setCognitoUser(user))
        .then(resolve)
        .catch(reject);
    });

  const logout = (opts?: PromiseOptions) =>
    cognitoLogout(opts).then(() => {
      setCognitoUser(undefined);
      setUserData(undefined);
      setCustomData(undefined);
    });
  const facebookLogin = () => initiateOauth('Facebook');
  const amazonLogin = () => initiateOauth('LoginWithAmazon');

  const updateUser = (opts?: PromiseOptions) =>
    new Promise((resolve) => {
      cognitoUpdateUser(opts).then(setCognitoUser).then(resolve).catch(logout);
    });

  const getHeaders = (): Obj => {
    const token = customData?.token;
    return token ? { token } : {};
  };

  // on mount check oauth
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (isOauthStarted()) {
      completeOauth({ signal })
        .then(setCognitoUser)
        .then(() => setIsLoaded(true));
    } else {
      cognitoUpdateUser({ signal })
        .then(setCognitoUser)
        .catch(() => ({})) // do nothing if not logged in
        .then(() => setIsLoaded(true));
    }
    return () => controller.abort();
  }, []);

  // fetch cogntio data on login, remove data on logout
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (cognitoUser) {
      getCognitoUserData(cognitoUser, { signal }).then(setUserData);
    }
    return () => controller.abort();
  }, [cognitoUser]);

  // fetch custom user data and schedule next update after cognito login
  useEffect(() => {
    let timeout: number;
    const controller = new AbortController();
    const { signal } = controller;
    if (userData) {
      const { sub, email } = userData;
      getCustomData(sub, email, { signal })
        .then(setCustomData)
        .then(() => {
          const mins = 15;
          const waitFor = mins * 60 * 1000;
          timeout = window.setTimeout(() => updateUser({ signal }), waitFor);
        })
        .catch(logout);
    }
    return () => {
      timeout && window.clearTimeout(timeout);
      controller.abort();
    };
  }, [userData]);

  return {
    cognitoUser,
    userData,
    customData,
    isLoaded,
    login,
    logout,
    updateUser,
    getHeaders,
    facebookLogin,
    amazonLogin,
    isOauth,
  };
};

export const UserStore = createContainer(State);
