import { useState, useEffect } from 'react';

const getWidth = () =>
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;

export const useDocumentWidth = () => {
  const [documentWidth, setWidth] = useState<number>(0);

  useEffect(() => {
    const listener = () => setWidth(getWidth());
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, []);

  return [documentWidth];
};
