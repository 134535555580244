import React from 'react';
import ReactCardFlip from 'react-card-flip';
import ProductCard from '../ProductCard';

interface Props {
  front: Product;
  back: Product;
  isFlipped: boolean;
}

const ProductFlipCard: React.FC<Props> = ({ front, back, isFlipped }) => (
  <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
    <ProductCard style={{ margin: 0 }} {...front} isDataHidden={true} />
    <ProductCard style={{ margin: 0 }} {...back} isDataHidden={true} />
  </ReactCardFlip>
);

export default ProductFlipCard;
