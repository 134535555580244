import React from 'react';
import Header from '../components/Header';
import PageStyle from '../components/PageStyle';
import Divider from '../components/Divider';

const About = () => {
  return (
    <>
      <Header title="Merch Data" />
      <PageStyle style={{ marginTop: '2rem' }}>
        <h2>What is Merch Data?</h2>
        <p>
          {`Merch Data is a powerful but easy-to-use analytical tool designed
          with the intention of identifying top selling trends while also
          decreasing the amount of time you need to spend doing research for
          your business. As engineers and MBA sellers ourselves, we built
          this site because we needed a way to analyze the Merch market
          without wasting time on forums and retail sites trying to see what
          designs are selling.`}
        </p>
        <Divider style={{ margin: '2rem 0' }} />
        <h2>View Listing Data</h2>
        <p>
          {`Listing data is sorted into three categories - best
          selling, newest, and rising. Below
          is a more detailed look into how each category is built.`}
        </p>
        <div style={{ marginLeft: '3rem' }}>
          <div style={{ margin: '2rem 0' }}>
            <h3>Best Selling</h3>
            <p>
              These are listings with the lowest sales ranks. The lower the
              sales rank, the faster it is selling. Once we gather information
              on every merch shirt being sold, we sort them and then add the top
              ones to our database. So we had to find a good balance for our
              users.
            </p>
          </div>
          <div style={{ margin: '2rem 0' }}>
            <h3>Rising</h3>
            <p>
              These are the newest listings sorted by lowest sales ranks. This
              is the category most sellers use to identify trends and niches.
            </p>
          </div>
          <div style={{ margin: '2rem 0' }}>
            <h3>Newest</h3>
            <p>
              {`These are the listings that were uploaded to the marketplace in
              the past few days. You can use this category to find niches that are being uploaded.`}
            </p>
          </div>
        </div>

        <Divider style={{ margin: '2rem 0' }} />
        <h2>Niche Data</h2>
        <p>
          Every time we update our data, we group similar listings into Niches.
          Each of these groups are analyzed based on their average sales
          rankings, percent that have sold, and the single best sales rank among
          them. Our niche finder has been extremely helpful in identifying new
          niches before they become bloated with too many designs. Try it out
          and you might be surprised what you find!
        </p>
        <Divider style={{ margin: '2rem 0' }} />
        <h2>Daily Updates?</h2>
        <p>
          {`Yes! The most important part to selling shirts is staying up to date
          on the current trends of the market. That's why we think daily updates
          are critical to beating the market. We update all our content once a
          day. Keep an eye on the "last updated" and "next update" counters.
          Monthly subscriptions from users like you help pay for the cost of
          gathering this much data on a daily basis.`}
        </p>
      </PageStyle>
    </>
  );
};

export default About;
