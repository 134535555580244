import React from 'react';
import NicheCard from '../NicheCard';

interface ViewerProps {
  niches: Niche[];
}

const NicheViewer: React.FC<ViewerProps> = ({ niches }) => (
  <div className="row" style={{ margin: '0 -1rem' }}>
    {niches.map((niche) => (
      <div
        className="col-xs-12 col-sm-6 col-lg-4 col-no-gutter"
        key={`${niche.rank}_${niche.averageSalesRank}`}
      >
        <NicheCard {...niche} />
      </div>
    ))}
  </div>
);

export default NicheViewer;
