import { env } from '../../utils/env';
import { post } from '../../services/api';

const ENDPOINT = env('REACT_APP_API_ENDPOINT');

interface StartSubscriptionParams {
  token?: string;
  coupon?: string;
  customerID: string;
}

export const startSubscription = (
  { customerID, token, coupon }: StartSubscriptionParams,
  opts?: PromiseOptions
) => {
  const url = `${ENDPOINT}${env('REACT_APP_ENDPOINT_POST_START_SUBSCRIPTION')}`;
  const body = JSON.stringify({
    customerID,
    token,
    coupon,
  });
  return post({ url, body }, opts);
};

interface StopSubscriptionParams {
  customerID: string;
}

export const stopSubscription = (
  { customerID }: StopSubscriptionParams,
  opts?: PromiseOptions
) => {
  const url = `${ENDPOINT}${env('REACT_APP_ENDPOINT_POST_STOP_SUBSCRIPTION')}`;
  const body = JSON.stringify({
    customerID,
  });
  return post({ url, body }, opts);
};
