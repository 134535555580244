import React, { useState } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import { DataModalStore } from '../../stores/App/DataModalStore';
import './DataModal.css';
import Loader from '../Loader';

const DataModal: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { isOpen, setIsOpen, reset } = DataModalStore.useContainer();

  const { pathname } = useLocation();

  let isValidPath = true;
  switch (pathname) {
    case '/settings/':
    case '/settings/payment/':
    case '/settings/discounts/':
    case '/settings/subscription/':
    case '/contact/':
      isValidPath = false;
      break;
  }

  const onRefresh = () => {
    setIsLoading(true);
    reset().then(() => {
      setIsLoading(false);
      setIsOpen(false);
    });
  };

  return (
    <Modal
      isOpen={isOpen && isValidPath}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName="modal-overlay"
      className="modal-body"
    >
      <div className="row top-xs">
        <div className="col-xs-11">
          <h3>Our data has just updated!</h3>
        </div>
        <button
          type="button"
          className="button button-icon col-xs-1 top-xs center-xs"
          onClick={() => setIsOpen(false)}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
      <p style={{ marginTop: 0 }}>
        Click below to load the newest data or close this box to continue
        looking at the current data.
      </p>
      <div className="row">
        <div className="col-xs-6">
          <button
            type="button"
            className="button button-wide button-gray"
            onClick={() => setIsOpen(false)}
          >
            <span>Close</span>
          </button>
        </div>
        <div className="col-xs-6">
          <button
            type="button"
            className="button button-wide"
            onClick={onRefresh}
          >
            {isLoading ? <Loader /> : <span>Load New Data</span>}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DataModal;
