import React, { useState, useEffect } from 'react';
import { UserStore } from '../stores/User';
import { TimerStore } from '../stores/Data/Timer';
import { PopSocketsFeaturedTM } from '../stores/Data/Products';
import { NichesStore } from '../stores/Data/Niches';
import PageContainer from '../components/PageContainer';
import Header from '../components/Header';

const Diagnostics: React.FC = () => {
  const [tokens, setTokens] = useState<string[]>([]);

  const { logout, userData, customData } = UserStore.useContainer();

  const { lastUpdate, nextUpdate } = TimerStore.useContainer();

  // const { key: keyA } = PopSocketsFeaturedNTM.useContainer();
  const {
    products,
    getNextPage,
    isLoading: isLoadingProducts,
    hasAll,
  } = PopSocketsFeaturedTM.useContainer();

  const {
    niches,
    getNextPage: getNextPageNiches,
    isLoading: isloadingNiches,
    hasAll: hasAllNiches,
  } = NichesStore.useContainer();

  useEffect(() => {
    const token = customData?.token;
    if (token) {
      const newtokens = [...tokens];
      newtokens.push(token);
      setTokens(newtokens);
    }
  }, [customData]);

  const onClickLogout = () => {
    setTokens([]);
    logout();
  };

  return (
    <>
      <Header title={'Diagnostics'} />
      <PageContainer>
        <h3>Cognito User Object</h3>
        <p>{JSON.stringify(userData)}</p>
        <h3>MD User Object</h3>
        <p>{JSON.stringify(customData)}</p>

        <button type="button" onClick={onClickLogout}>
          Logout
        </button>

        <h3>Auth Tokens</h3>
        <div>
          {tokens.map((token) => (
            <p key={token}>{token}</p>
          ))}
        </div>

        <div>
          <h3>Timer</h3>
          <h5>Next Update: {nextUpdate}</h5>
          <h5>Last Update: {lastUpdate}</h5>
        </div>

        <div>
          <h3>Product Container (Popsockets)</h3>
          <div>
            {products.map(({ title, asin }) => (
              <p key={asin}>{title}</p>
            ))}
          </div>
          <button
            type="button"
            onClick={() => getNextPage()}
            disabled={isLoadingProducts || hasAll}
          >
            Fetch Next Page
          </button>
          <span>Num Results: {products.length}</span>
        </div>

        <div>
          <h3>Niches Container</h3>
          <div>
            {niches.map(({ rank, listings }) => (
              <p
                key={`${listings[0].asin}${rank}`}
              >{`${rank} Num Listings: ${listings.length}`}</p>
            ))}
          </div>
          <button
            type="button"
            onClick={() => getNextPageNiches()}
            disabled={isloadingNiches || hasAllNiches}
          >
            Fetch Next Page
          </button>
          <span>Num Results: {niches.length}</span>
        </div>
      </PageContainer>
    </>
  );
};

export default Diagnostics;
