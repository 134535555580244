import Base64 from 'crypto-js/enc-base64';
import Utf8 from 'crypto-js/enc-utf8';
import Hex from 'crypto-js/enc-hex';
import SHA256 from 'crypto-js/sha256';
import HMACSHA256 from 'crypto-js/hmac-sha256';

export const sha256 = (s: string | CryptoJS.WordArray) => SHA256(s as any);

export const hmacSha256 = (
  key: string | CryptoJS.WordArray,
  data: string | CryptoJS.WordArray
) => HMACSHA256(data as any, key); // eslint-disable-line

export const randomString = (length: number) => {
  const CHARSET =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const arr = new Array(length).fill(0);
  const letters = arr.map(
    () => CHARSET[Math.floor(Math.random() * CHARSET.length)]
  );
  return letters.join('');
};

export const utf8ToWordArr = (s: string) => Utf8.parse(s);
export const wordArrToUtf8 = (warr: CryptoJS.WordArray) => Utf8.stringify(warr);
export const wordArrToHex = (warr: CryptoJS.WordArray) => Hex.stringify(warr);

export const base64URLEncode = (warr: CryptoJS.WordArray) =>
  Base64.stringify(warr)
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
