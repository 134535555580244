import React, { useState, useEffect } from 'react';
import ProductFlipCard from '../ProductFlipCard';

interface Props {
  products: Product[];
  isHiddenOnSmall?: boolean;
}

const HeroProducts: React.FC<Props> = ({ isHiddenOnSmall, products }) => {
  const productClassNames = ['col-xs-6', 'col-sm-3'];

  if (isHiddenOnSmall) {
    productClassNames.push('col-xs-hide');
    productClassNames.push('col-sm-show');
  }

  const pairwise = products.reduce<Array<Array<Product>>>((acc, _, idx) => {
    if (idx % 2) acc.push(products.slice(idx, idx + 2));
    return acc;
  }, []);

  const [flipState, setIsFlipState] = useState<Array<boolean>>(
    pairwise.map(() => false)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (!document.hidden) {
        const newFlipState = [...flipState];
        const ranIdx = Math.floor(Math.random() * flipState.length);
        newFlipState[ranIdx] = !newFlipState[ranIdx];
        setIsFlipState(newFlipState);
      }
    }, 2500);
    return () => clearInterval(interval);
  }, []);

  return (
    <React.Fragment>
      {pairwise.map((products, idx) => {
        const [product1, product2] = products;
        const key = product1?.asin + product2?.asin;
        return (
          <div className={productClassNames.join(' ')} key={key}>
            <ProductFlipCard
              front={product1}
              back={product2}
              isFlipped={flipState[idx]}
            />
          </div>
        );
      })}
    </React.Fragment>
  );
};

export default HeroProducts;
