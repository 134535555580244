import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import './SidebarMenu.css';
import MenuItem from '../../components/MenuItem';
import { SidebarStore } from '../../stores/App/Sidebar';
import Row from '../../components/Row';
import Timer from '../../components/Timer';
import Divider from '../../components/Divider';
import { Link } from 'react-router-dom';

const SidebarMenu: React.FC = () => {
  const { isDocked, setIsDockOpen } = SidebarStore.useContainer();

  return (
    <div className="sidebarmenu">
      <header>
        <Row>
          <Link to="/" className="header-title" style={{ fontSize: '2rem' }}>
            Merch Data
          </Link>
          {!isDocked && (
            <div className="menu">
              <button
                type="button"
                className="button button-icon"
                onClick={() => setIsDockOpen(false)}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
          )}
        </Row>
      </header>
      <div>
        <Timer />
      </div>
      <Divider />
      <MenuItem title="Home" to="/" />
      <MenuItem title="Shirts" to="/shirts/" />
      <MenuItem title="PopSockets" to="/popsockets/" />
      <MenuItem title="Niches" to="/niches/" />
      <MenuItem title="Settings" to="/settings/" />
    </div>
  );
};

export default SidebarMenu;
