import React from 'react';
import './ProductCard.css';
import Row from '../Row';

interface Props extends Product {
  isLinkHidden?: boolean;
  isDataHidden?: boolean;
  isLoading?: boolean;
}

type DivProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

export const toProductDate = (d: Date) => {
  const monthNames = [
    'Jan.',
    'Feb.',
    'Mar.',
    'Apr.',
    'May',
    'Jun.',
    'Jul.',
    'Aug.',
    'Sep.',
    'Oct.',
    'Nov.',
    'Dec.',
  ];
  return `${monthNames[d.getMonth()]} ${d.getDate() - 1}, ${d.getFullYear()}`;
};

const ProductCard: React.FC<Props & DivProps> = ({
  trademarked, // eslint-disable-line
  isDataHidden,
  isLinkHidden,
  imgUrl,
  rank,
  salesRank,
  uploaded,
  title,
  isPopSocket,
  link,
  className,
  ...divProps
}) => {
  const classNames = ['productcard', className];
  return (
    <div {...divProps} className={classNames.join(' ')}>
      <img src={imgUrl} />
      {!isDataHidden && (
        <div>
          {!isLinkHidden && (
            <Row className="productcard-rank">
              <span>{rank}.</span>
              <a
                href={link}
                className="box button button-miny"
                target="_blank"
                rel="noopener noreferrer"
              >
                View Listing
              </a>
            </Row>
          )}
          <Row className="productcard-data-row">
            <span>Sales Rank</span>
            <span>{salesRank.toLocaleString()}</span>
          </Row>
          {!isPopSocket && (
            <Row className="productcard-data-row">
              <span>Uploaded</span>
              <span>{toProductDate(uploaded)}</span>
            </Row>
          )}
          <div className="productcard-title">
            <span>{title}</span>
            <div className="productcard-title-fade" />
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductCard;
