import { Credentials } from '../cognito/federation';
import { sign } from './signer';

const api = <T>(requestParams: APIParams, opts?: PromiseOptions): Promise<T> =>
  new Promise((resolve, reject) => {
    if (opts?.signal) opts.signal.addEventListener('abort', reject);
    Credentials.get()
      .then((credentials) => {
        const signedParams = sign(requestParams, credentials);
        return fetch(signedParams.url, {
          signal: opts?.signal,
          headers: signedParams.headers,
          method: signedParams.method,
          body: signedParams.body,
          mode: 'cors',
          cache: 'no-cache',
        });
      })
      .then(async (response) => {
        const json = await response.json();
        if (response.ok) resolve(json as T);
        else throw Error(json.userErrorMessage || json);
      })
      .catch((error) => reject(error.message));
  });

const queryObjToString = (q: { [key: string]: string }) =>
  Object.entries(q)
    .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
    .join('&');

export const get = <T>(
  params: GetParams,
  opts?: PromiseOptions
): Promise<T> => {
  const { url, queryString } = params;
  const qs = `${url}${queryString ? `?${queryObjToString(queryString)}` : ''}`;
  return api<T>({ url: qs, headers: params.headers, method: 'GET' }, opts);
};

export const post = <T>(
  params: PostParams,
  opts?: PromiseOptions
): Promise<T> => {
  const { url, headers, body } = params;
  return api<T>({ url, headers, method: 'POST', body }, opts);
};
