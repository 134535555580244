import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import { TimerStore } from '../../stores/Data/Timer';
import './Timer.css';
import useIsMounted from '../../hooks/useIsMounted';

interface LoaderProps {
  color?: string;
}

const Loader: React.FC<LoaderProps> = ({ color }) => (
  <div className="row center-xs ">
    <ReactLoading
      type={'spin'}
      color={color ? color : 'var(--color-primary'}
      height={24}
      width={24}
    />
  </div>
);

interface NextTimerProps {
  isLoading: boolean;
  error: string;
  countdown: number;
}

const formatCountdown = (ts: number): string => {
  if (ts < 0) return '00:00:00';
  let totalSeconds = ts / 1000;
  const hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  const forwardZero = (n: number) => `0${n}`.slice(-2);
  const strHours = forwardZero(hours);
  const strMinutes = forwardZero(minutes);
  const strSeconds = forwardZero(seconds);
  return `${strHours}:${strMinutes}:${strSeconds}`;
};

const NextTimer: React.FC<NextTimerProps> = ({
  isLoading,
  error,
  countdown,
}) => {
  const Container: React.FC = ({ children }) => (
    <div className="timer timer-next">{children}</div>
  );

  if (isLoading)
    return (
      <Container>
        <Loader color="white" />
      </Container>
    );

  // const { reset } = DataModalStore.useContainer();
  if (error === '__expired__')
    return (
      <button className="timer button-wide">
        <span>Load New Data</span>
      </button>
    );

  if (error === '__stale__')
    return (
      <Container>
        <span>
          {'Our next batch of data, is running late. Please check back soon.'}
        </span>
      </Container>
    );

  return (
    <Container>
      <span>Next Update:</span>
      <span>{formatCountdown(countdown)}</span>
    </Container>
  );
};

const Timer: React.FC = () => {
  const [countdown, setCountdown] = useState<number>(0);
  const {
    isLoading,
    lastUpdate,
    nextUpdate,
    error,
  } = TimerStore.useContainer();

  const isMounted = useIsMounted();

  useEffect(() => {
    if (nextUpdate > 0) {
      const now = Math.floor(Date.now() / 1000) * 1000;
      const start = nextUpdate - now;
      if (isMounted) setCountdown(start);
    }
  }, [nextUpdate]);

  useEffect(() => {
    if (countdown > 0) {
      const timeout = setTimeout(
        () => isMounted && setCountdown(countdown - 1000),
        1000
      );
      return () => clearTimeout(timeout);
    }
  }, [countdown]);

  return (
    <div>
      <NextTimer {...{ isLoading, countdown, error }} />
      <div className="timer timer-last">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <span>Last Update:</span>
            <span className="time">
              {new Date(lastUpdate).toLocaleString()}
            </span>
          </>
        )}
      </div>
    </div>
  );
};

export default Timer;
