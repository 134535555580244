import React from 'react';

type DivProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const Row: React.FC<DivProps> = ({ children, className, ...divprops }) => {
  const classNames = ['row middle-xs between-xs', className || ''];
  return (
    <div
      className={classNames.join(' ')}
      {...divprops}
      style={{ margin: 0, width: '100%' }}
    >
      {children}
    </div>
  );
};

export default Row;
