import React from 'react';
// import { UserStore } from '../stores/User';
import Header from '../components/Header';
import PageStyle from '../components/PageStyle';
import { Link } from 'react-router-dom';
import { ShirtsRisingNTM, PopSocketsRisingNTM } from '../stores/Data/Products';
import ProductCard from '../components/ProductCard';
import Timer from '../components/Timer';
import { NichesStore } from '../stores/Data/Niches';
import NicheCard from '../components/NicheCard';
import PriceTable from '../components/PriceTable';
import Footer from '../components/Footer';
import HeroProducts from '../components/HeroProducts';

const Landing: React.FC = () => {
  const { products: shirts, isLoading } = ShirtsRisingNTM.useContainer();
  const { niches } = NichesStore.useContainer();
  const { products: popsockets } = PopSocketsRisingNTM.useContainer();
  return (
    <>
      <Header
        title="Merch Data"
        Right={() => (
          <Link
            to="/auth/login/"
            className="button button-md"
            style={{ margin: '0.5rem 0' }}
          >
            Login
          </Link>
        )}
      />
      <PageStyle>
        <div className="row center-xs" style={{ marginTop: '5rem' }}>
          <h1 className="col-xs">Essential data at your fingertips</h1>
        </div>
        <div className="row center-xs">
          <h3 className="col-xs">
            <span className="line">See Competition. Gain Insights.&nbsp;</span>
            <span className="line">Find Ideas. Win.</span>
          </h3>
        </div>
        <div className="row center-xs">
          <Link
            to="/auth/login/"
            className="button button-lg"
            style={{ margin: '2rem 0' }}
          >
            Get Started for Free
          </Link>
        </div>
        {!isLoading && (
          <div className="row fadeInUp" style={{ marginTop: '3rem' }}>
            <HeroProducts products={shirts.slice(0, 9)} />
            <HeroProducts
              products={shirts.slice(9, 18)}
              isHiddenOnSmall={true}
            />
          </div>
        )}
      </PageStyle>
      <div className="gradient" style={{ margin: '3rem 0' }}>
        <PageStyle
          style={{
            backgroundColor: 'transparent',
            paddingTop: '3rem',
            paddingBottom: '3rem',
          }}
        >
          <div className="row center-xs">
            <h1 className="col-xs">Is the merch market overwhelming?</h1>
          </div>
          <div className="row center-xs">
            <h3 className="col-xs">
              <span className="line">
                Effortlessly browse the hottest designs and niches,&nbsp;
              </span>
              <span className="line">
                so you can make listings that sell now.
              </span>
            </h3>
          </div>
          <div className="row center-xs">
            <Link
              to="/auth/login/"
              className="button button-lg button-transparent"
              style={{ margin: '2rem 0' }}
            >
              Get Started for Free
            </Link>
          </div>
        </PageStyle>
      </div>
      <PageStyle style={{ margin: '5rem auto' }}>
        <div className="row center-xs">
          <div className="col-xs-12 center-xs">
            <h1>Niches</h1>
            <h3>
              <span className="line">
                Quickly identify trending themes and ideas
              </span>
            </h3>
          </div>
        </div>
        <div className="row center-xs" style={{ marginTop: '3rem' }}>
          {niches[0] && (
            <div className="col-xs-12 col-sm-6 col-md-4">
              <NicheCard {...niches[0]} isLinkHidden={true} />
            </div>
          )}
          {niches[1] && (
            <div className="col-xs-hide col-sm-show col-sm-6 col-md-4">
              <NicheCard {...niches[1]} isLinkHidden={true} />
            </div>
          )}
          {niches[2] && (
            <div className="col-xs-hide col-md-show col-md-4">
              <NicheCard {...niches[2]} isLinkHidden={true} />
            </div>
          )}
        </div>
      </PageStyle>
      <PageStyle style={{ margin: '5rem auto' }}>
        <div className="row center-xs">
          <div className="col-xs-12 center-xs">
            <h1>Shirts and PopSockets</h1>
            <h3>
              <span className="line">Track the top one thousand&nbsp;</span>
              <span className="line">
                best selling, newest, and rising listings.
              </span>
            </h3>
          </div>
        </div>
        <div className="row" style={{ marginTop: '3rem' }}>
          {shirts[0] && (
            <div className="col-xs-6 col-sm-4 col-md-3">
              <ProductCard {...shirts[0]} isLinkHidden={true} />
            </div>
          )}
          {shirts[1] && (
            <div className="col-xs-6 col-sm-4 col-md-3">
              <ProductCard {...shirts[1]} isLinkHidden={true} />
            </div>
          )}
          {shirts[2] && (
            <div className="col-xs-6 col-sm-4 col-md-3 col-xs-hide col-sm-show">
              <ProductCard {...shirts[2]} isLinkHidden={true} />
            </div>
          )}
          {shirts[3] && (
            <div className="col-md-3 col-xs-hide col-md-show">
              <ProductCard {...shirts[3]} isLinkHidden={true} />
            </div>
          )}
          {popsockets[0] && (
            <div className="col-xs-6 col-sm-4 col-md-3">
              <ProductCard {...popsockets[0]} isLinkHidden={true} />
            </div>
          )}
          {popsockets[1] && (
            <div className="col-xs-6 col-sm-4 col-md-3">
              <ProductCard {...popsockets[1]} isLinkHidden={true} />
            </div>
          )}
          {popsockets[2] && (
            <div className="col-xs-6 col-sm-4 col-md-3 col-xs-hide col-sm-show ">
              <ProductCard {...popsockets[2]} isLinkHidden={true} />
            </div>
          )}
          {popsockets[3] && (
            <div className="col-md-3 col-xs-hide col-md-show">
              <ProductCard {...popsockets[3]} isLinkHidden={true} />
            </div>
          )}
        </div>
      </PageStyle>
      <PageStyle style={{ margin: '5rem auto 10rem auto' }}>
        <div className="row center-xs">
          <div className="col-xs-12 col-sm-6 center-xs">
            <h1>Updated Daily</h1>
            <h3>
              <span className="line">Get the latest data every single day</span>
            </h3>
          </div>
          <div className="col-xs-12 col-sm-6">
            <Timer />
          </div>
        </div>
      </PageStyle>
      <div className="gradient" style={{ marginTop: '3rem' }}>
        <PageStyle
          style={{
            backgroundColor: 'transparent',
            paddingTop: '3rem',
            paddingBottom: '3rem',
          }}
        >
          <div className="row center-xs">
            <h1 className="col-xs">Start your free 3-day trial.</h1>
          </div>
          <div className="row center-xs">
            <h2 className="col-xs">
              <span className="line">No credit card requred.&nbsp;</span>
              <span className="line">Cancel Anytime.</span>
            </h2>
          </div>
          <div className="row center-xs">
            <h2 className="col-xs">
              <span className="line">$10/month</span>
            </h2>
          </div>
          <div
            className="row center-xs"
            style={{ marginTop: '2rem', marginBottom: '1rem' }}
          >
            <div className="col-xs-11 col-md-8">
              <PriceTable />
            </div>
          </div>
          <div className="row center-xs">
            <Link
              to="/auth/login/"
              className="button button-lg button-transparent"
              style={{ margin: '2rem 0' }}
            >
              Get Started for Free
            </Link>
          </div>
        </PageStyle>
      </div>
      <Footer />
    </>
  );
};

export default Landing;
