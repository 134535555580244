import React from 'react';
import PageStyle from '../PageStyle';
import './Footer.css';
import Divider from '../Divider';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <PageStyle>
        <div className="row center-xs" style={{ margin: '4rem 0' }}>
          <div className="col-xs-4">
            <Link to="/contact/">Contact</Link>
          </div>
          <div className="col-xs-4">
            <Link to="/about/">About</Link>
          </div>
          <div className="col-xs-4">
            <Link to="/auth/login/">Login</Link>
          </div>
        </div>
      </PageStyle>
      <PageStyle>
        <p>
          We are not affiliated with Amazon.com. We do not advertise products or
          use affiliate marketing. We will never release personal data such as
          name, email, or credit card information. Credit card information is
          securely managed through Stripe, a 3rd party payment service. Merch
          Data is a paid service that uses its own servers and is funded through
          monthly subscriptions. Privacy Policy
        </p>
        <Divider />
        <p>
          The data on this site is hand picked from real sources. We use a
          proprietary algorithm to filter out trademarks and estimate sales
          rank, but cannot gaurantee that the results are 100% trademark free.
          The data on this site is to provide ideas, and designs should not be
          directly copied. The collective data on this site and format of
          presentation is under copyright by Merch Data.
        </p>
      </PageStyle>
    </footer>
  );
};

export default Footer;
