import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useFormik, FormikErrors } from 'formik';
import AuthModal from '../../components/AuthModal';
import Callout from '../../components/Callout';
import {
  createCognitoUser,
  forgotPasswordSubmit,
} from '../../services/cognito/core';
import Divider from '../../components/Divider';
import Loader from '../../components/Loader';

interface ResetField {
  email: string;
  code: string;
  passwordA: string;
  passwordB: string;
}

interface HistoryState {
  email?: string;
  message?: string;
}

const ResetPasswordConfirm: React.FC = () => {
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const { state } = useLocation<HistoryState>();
  const { email: prevEmail, message } = state || {};

  const formik = useFormik({
    initialValues: {
      email: prevEmail || '',
      code: '',
      passwordA: '',
      passwordB: '',
    },
    validate: (values) => {
      const e: FormikErrors<ResetField> = {};
      const { passwordA, passwordB, code, email } = values;
      setError('');
      if (!email) {
        e.email = 'An email is required.';
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        e.email = 'Invalid email address.';
      }

      if (!code) {
        e.code = 'Please enter your verification code.';
      }
      if (!passwordA) {
        e.passwordA = 'A new password is required.';
      } else if (passwordA.length < 6) {
        e.passwordA =
          'Your new password must be more than six characters long.';
      }

      if (!passwordA) {
        e.passwordB = 'Please retype your password.';
      } else if (passwordA !== passwordB) {
        e.passwordB = 'Your passwords must match.';
      }

      return e;
    },
    validateOnChange: false,
    onSubmit: (values) => {
      const { passwordA, code, email } = values;
      setIsLoading(true);
      const user = createCognitoUser(email || '');
      forgotPasswordSubmit(user, code, passwordA)
        .then(() => setIsSuccess(true))
        .catch((error) => {
          console.log(error);
          setError(error.message);
        })
        .then(() => setIsLoading(false));
    },
  });

  const { passwordA, passwordB, code, email } = formik.values;
  const { errors } = formik;

  const errorMessage = Object.values(errors)[0] || error || '';

  return (
    <AuthModal>
      <div
        className="row center-xs"
        style={{ margin: 0, marginBottom: '1rem' }}
      >
        <h2>Password Reset</h2>
      </div>
      {isSuccess ? (
        <>
          <Callout status="success" style={{ marginBottom: '1rem' }}>
            <h4>Success</h4>
            <p>Your new password is set. Click below to return to the login.</p>
          </Callout>
          <Link to="/auth/login/email/" className="button button-wide">
            Return To Login
          </Link>
        </>
      ) : (
        <>
          <p>
            {message
              ? message
              : 'Please fill out the following form to complete your password reset.'}
          </p>
          {errorMessage && (
            <Callout status="error" style={{ marginBottom: '1rem' }}>
              <h4>Error</h4>
              <p>{errorMessage}</p>
            </Callout>
          )}
          <form onSubmit={formik.handleSubmit} autoComplete="off">
            <input hidden name="username" type="email" value={email} readOnly />
            <input
              hidden
              name="password"
              type="password"
              value={passwordB}
              readOnly
            />
            {!prevEmail && (
              <label>
                Email
                <input
                  name="email"
                  id="email"
                  type="email"
                  autoComplete="new-password"
                  onChange={formik.handleChange}
                  value={email}
                  placeholder="Email"
                  style={{ marginBottom: '1rem' }}
                />
              </label>
            )}
            <label>
              Verification Code
              <input
                name="code"
                id="code"
                type="text"
                autoComplete="new-password"
                onChange={formik.handleChange}
                value={code}
                placeholder="Verification Code"
                style={{ marginBottom: '1rem' }}
              />
            </label>
            <label>
              New Password
              <input
                name="passwordA"
                id="passwordA"
                type="password"
                autoComplete="new-password"
                onChange={formik.handleChange}
                value={passwordA}
                placeholder="New Password"
                style={{ marginBottom: '1rem' }}
              />
            </label>
            <label>
              New Password Again
              <input
                name="passwordB"
                id="passwordB"
                type="password"
                autoComplete="new-password"
                onChange={formik.handleChange}
                value={passwordB}
                placeholder="New Password Again"
                style={{ marginBottom: '1rem' }}
              />
            </label>
            <button type="submit" className="button-wide">
              {isLoading ? <Loader /> : <span>Complete Reset</span>}
            </button>
          </form>
          <Divider />
          <p>
            If you are having issues, please click below to receive a new
            verification code.
          </p>
          <Link
            to="/auth/password/reset/start/"
            className="button button-wide"
            style={{ marginTop: '1rem' }}
          >
            Restart Password Reset
          </Link>
        </>
      )}
    </AuthModal>
  );
};

export default ResetPasswordConfirm;
