import { useState, useEffect } from 'react';
import { createContainer } from 'unstated-next';
import { getTimer } from './api';
import { useAuthEvents } from '../../../hooks/useAuthEvents';

type TimerError = '' | '__noload__' | '__stale__' | '__expired__';

const State = () => {
  const [error, setError] = useState<TimerError>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [lastUpdate, setLastUpdate] = useState<number>(0);
  const [nextUpdate, setNextUpdate] = useState<number>(0);

  const updateTimer = (opts?: PromiseOptions): Promise<void> =>
    new Promise((resolve) => {
      setError('');
      setIsLoading(true);
      getTimer(opts)
        .then(({ lastUpdate, nextUpdate }) => {
          if (nextUpdate * 1000 < Date.now()) {
            setError('__stale__');
          }
          setLastUpdate(lastUpdate * 1000);
          setNextUpdate(nextUpdate * 1000);
        })
        .then(() => setIsLoading(false))
        .catch(() => setError('__noload__'))
        .then(() => setIsLoading(false))
        .then(resolve);
    });

  // on credential load update timer
  useAuthEvents({ onCredentialLoaded: updateTimer });

  //  On timer update schedule error
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (nextUpdate > Date.now()) {
      const callIn = nextUpdate - Date.now();
      timeout = setTimeout(() => setError('__expired__'), callIn);
    }

    return () => clearTimeout(timeout);
  }, [nextUpdate]);

  return {
    error,
    isLoading,
    lastUpdate,
    nextUpdate,
    reset: updateTimer,
  };
};

export const TimerStore = createContainer(State);
