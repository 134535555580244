import React from 'react';
import ReactLoading from 'react-loading';

const Loader = () => (
  <div className="row center-xs ">
    <ReactLoading type={'spin'} color={'white'} height={24} width={24} />
  </div>
);

export default Loader;
