import { useEffect } from 'react';
import { TimerStore } from '../stores/Data/Timer';

interface Actions {
  onTimerEnd?: PromFunc;
}

export const useTimerEvents = (actions: Actions) => {
  const { nextUpdate } = TimerStore.useContainer();

  const { onTimerEnd } = actions;
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    let timeout: NodeJS.Timeout;

    if (nextUpdate > Date.now()) {
      const callIn = nextUpdate - Date.now();
      timeout = setTimeout(() => {
        if (!signal.aborted) onTimerEnd && onTimerEnd({ signal });
      }, callIn);
    }

    return () => {
      controller.abort();
      clearTimeout(timeout);
    };
  }, [nextUpdate]);
};
