import React, { useState } from 'react';
import ReactLoading from 'react-loading';
import Header from '../../components/Header';
import PageStyle from '../../components/PageStyle';
import Callout from '../../components/Callout';
import { UserStore } from '../../stores/User';
import { Redirect } from 'react-router-dom';
import { validateDiscount, applyDiscount } from './api';

const Loader = () => (
  <div className="row center-xs ">
    <ReactLoading type={'spin'} color={'white'} height={24} width={24} />
  </div>
);

const Discounts = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [code, setCode] = useState<string>('');

  const { customData, userData, updateUser } = UserStore.useContainer();
  if (!(customData && userData)) return <Redirect to="/" />;
  const { customer } = customData;
  const { id: customerID, subscriptions } = customer;
  const { sub } = userData;

  const validateAndApply = async () => {
    setIsLoading(true);
    try {
      await validateDiscount({ sub, code });
      await applyDiscount({ sub, code, customerID });
      await updateUser();
      setCode('');
      setIsSuccess(true);
    } catch (error) {
      setError(error);
    }
    setIsLoading(false);
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    validateAndApply();
  };

  const subscription = subscriptions.data[0];

  const currentdiscount = subscription?.discount?.coupon?.metadata?.description;

  return (
    <>
      <Header title="Discounts" />
      <PageStyle>
        {currentdiscount ? (
          <Callout status="info" style={{ margin: '1rem 0' }}>
            <h4>Current Discount</h4>
            <p style={{ margin: 0 }}>{currentdiscount}</p>
          </Callout>
        ) : (
          <Callout status="info" style={{ margin: '1rem 0' }}>
            <h4>Discounts</h4>
            <p style={{ margin: 0 }}>
              If you were provided a discount code you may enter it here.
            </p>
          </Callout>
        )}
        {error && (
          <Callout status="error" style={{ marginBottom: '1rem' }}>
            <h4>Error</h4>
            <p style={{ margin: 0 }}>{error}</p>
          </Callout>
        )}
        {isSuccess && (
          <Callout status="success" style={{ marginBottom: '1rem' }}>
            <h4>Success</h4>
            <p style={{ margin: 0 }}>
              A new discount has been applied to your account.
            </p>
          </Callout>
        )}
        <form onSubmit={onSubmit} style={{ marginTop: '1rem' }}>
          <label>
            Discount Code
            <input
              value={code}
              onChange={(e) => {
                setCode(e.target.value);
                setError('');
                setIsSuccess(false);
              }}
            />
          </label>
          <button
            type="submit"
            disabled={isLoading}
            className="button-wide"
            style={{ marginTop: '1.75rem' }}
          >
            {isLoading ? <Loader /> : <span>Add Discount</span>}
          </button>
        </form>
      </PageStyle>
    </>
  );
};

export default Discounts;
