import React, { useState } from 'react';
import Header from '../../components/Header';
import PageStyle from '../../components/PageStyle';
import { UserStore } from '../../stores/User';
import { Redirect } from 'react-router-dom';
import PaymentForm from '../../components/PaymentForm';
import Callout from '../../components/Callout';
import { startSubscription, stopSubscription } from './api';
import Loader from '../../components/Loader';

const formatPeriodEnd = (ts: number) => {
  const date = new Date(ts * 1000);
  const lds = date.toLocaleDateString();
  const lts = date.toLocaleTimeString();
  return `${lds}, at ${lts}
  `;
};

const Subscription: React.FC = () => {
  const [errorNoCard, setErrorNoCard] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>();
  const { customData, updateUser } = UserStore.useContainer();
  if (!customData) return <Redirect to="/logout/" />;
  const { customer } = customData;
  const { subscriptions, sources, id: customerID } = customer;
  const { data: subscriptionData } = subscriptions;
  const subscription = (subscriptionData || [0])[0];
  const { data: sourceData } = sources;
  const card = (sourceData || [])[0];
  const isSubscriptionRunning =
    subscription &&
    !subscription.cancel_at_period_end &&
    subscription.current_period_end * 1000 > Date.now();

  const cost = subscription && subscription.plan.amount / 100;

  const onToken = (token: string): Promise<void> =>
    new Promise((resolve, reject) => {
      startSubscription({ token, customerID })
        .then(() => updateUser())
        .then(() => resolve())
        .catch((error) => {
          setErrorNoCard(error);
          reject();
        });
    });

  const onCancel = () =>
    new Promise((resolve, reject) => {
      setIsLoading(true);
      stopSubscription({ customerID })
        .then(() => updateUser())
        .then(() => resolve())
        .catch((error) => {
          setError(error);
          reject();
        })
        .then(() => setIsLoading(false));
    });

  const onStart = () =>
    new Promise((resolve, reject) => {
      setIsLoading(true);
      startSubscription({ customerID })
        .then(() => updateUser())
        .then(() => resolve())
        .catch((error) => {
          setError(error);
          reject();
        })
        .then(() => setIsLoading(false));
    });

  return (
    <>
      <Header title="Subscription" />
      <PageStyle>
        {card && (
          <>
            <Callout status="info" style={{ margin: '1rem 0' }}>
              {subscription && (
                <>
                  <h4>Current Subscription</h4>
                  {isSubscriptionRunning ? (
                    <p style={{ margin: 0 }}>
                      You will be charged ${cost} on{' '}
                      {formatPeriodEnd(subscription.current_period_end)}
                    </p>
                  ) : (
                    <p style={{ margin: 0 }}>
                      Your access will end on{' '}
                      {formatPeriodEnd(subscription.current_period_end)}
                    </p>
                  )}
                </>
              )}
              {!subscription && (
                <>
                  <h4>Start Subscription</h4>
                  <p style={{ margin: 0 }}>
                    To start your subscription, please enter your preferred
                    payment method. You will be charged $10 every 30 days, and
                    can cancel at anytime.
                  </p>
                </>
              )}
            </Callout>
            {error && (
              <Callout status="error" style={{ marginBottom: '1rem' }}>
                <h4>Error:</h4>
                <p style={{ margin: 0 }}>{error}</p>
              </Callout>
            )}
            {isSubscriptionRunning ? (
              <button
                type="button"
                className="button-wide button-gray"
                onClick={onCancel}
              >
                {isLoading ? <Loader /> : <span>Cancel Subscription</span>}
              </button>
            ) : (
              <button type="button" className="button-wide" onClick={onStart}>
                {isLoading ? (
                  <Loader />
                ) : (
                  <span>
                    {subscription && !isSubscriptionRunning
                      ? 'Restart Subscription'
                      : 'Start Subscription'}
                  </span>
                )}
              </button>
            )}
          </>
        )}
        {!card && (
          <>
            <Callout status="info" style={{ margin: '1rem 0' }}>
              <h4>Add Payment Method</h4>
              <p style={{ margin: 0 }}>
                To start your subscription, please enter your preferred payment
                method. You will be charged $10 every 30 days, and can cancel at
                anytime.
              </p>
            </Callout>
            <PaymentForm
              onToken={onToken}
              buttonTitle="Start Subscription"
              error={errorNoCard}
            />
          </>
        )}
      </PageStyle>
    </>
  );
};

export default Subscription;
