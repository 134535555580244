import { env } from '../../utils/env';
import { post } from '../../services/api';

const ENDPOINT = env('REACT_APP_API_ENDPOINT');

interface UpdatePaymentParams {
  token: string;
  customerID: string;
}

export const updatePayment = (
  { token, customerID }: UpdatePaymentParams,
  opts?: PromiseOptions
) => {
  const url = `${ENDPOINT}${env('REACT_APP_ENDPOINT_POST_SET_SOURCE')}`;
  const body = JSON.stringify({
    customerID,
    token,
  });
  return post({ url, body }, opts);
};
