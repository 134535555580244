import { CognitoIdentityCredentials } from 'aws-sdk/global';
import { env } from '../../utils/env';
import { wordArrToHex, sha256, hmacSha256 } from '../../utils/cryptography';

const REGION = env('REACT_APP_REGION');

const SIGNING_ALGORITHM = 'AWS4-HMAC-SHA256';

const formatCanonicalHeaders = (headers: Obj) =>
  Object.keys(headers)
    .map((key) => ({
      key: key.toLowerCase(),
      value: headers[key] ? headers[key].trim().replace(/\s+/g, ' ') : '',
    }))
    .sort((a, b) => (a.key < b.key ? -1 : 1))
    .map((item) => item.key + ':' + item.value)
    .join('\n') + '\n';

const escapeRFC3986 = (s: string) =>
  s.replace(
    /[!'()*]/g,
    (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`
  );

const formatCanonicalQueryString = (qs: string) =>
  qs
    .split('&')
    .map((p) => {
      const keyVal = p.split('=');
      return keyVal.length === 1
        ? p
        : `${keyVal[0]}=${escapeRFC3986(keyVal[1])}`;
    })
    .sort((a, b) => {
      const keyA = a.split('=')[0];
      const keyB = b.split('=')[0];
      return keyA === keyB ? (a < b ? -1 : 1) : keyA < keyB ? -1 : 1;
    })
    .join('&');

const formatSignedHeaders = (headers: Obj) =>
  Object.keys(headers)
    .map((k) => k.toLowerCase())
    .sort()
    .join(';');

const digest = (p: string) => wordArrToHex(sha256(p)).toLowerCase();

const createCanoncical = (
  method: string,
  path?: string,
  headers?: { [key: string]: string },
  queryString?: string,
  body?: string
) =>
  [
    method,
    encodeURIComponent(path || '/').replace(/%2F/gi, '/'),
    queryString ? formatCanonicalQueryString(queryString) : '',
    headers ? formatCanonicalHeaders(headers) : '',
    headers ? formatSignedHeaders(headers) : '',
    digest(body || '').toLowerCase(),
  ].join('\n');

const createStringToSign = (
  dtStr: string,
  scope: string,
  hashedCanonical: string
) => `${SIGNING_ALGORITHM}\n${dtStr}\n${scope}\n${hashedCanonical}`;

const createSignature = (
  secretKey: string,
  dStr: string,
  service: string,
  stringToSign: string
) => {
  const kDate = hmacSha256(`AWS4${secretKey}`, dStr);
  const kRegion = hmacSha256(kDate, REGION);
  const kService = hmacSha256(kRegion, service);
  const kSigning = hmacSha256(kService, 'aws4_request');
  const signature = hmacSha256(kSigning, stringToSign);
  return wordArrToHex(signature);
};

const createAuthorizationHeader = (
  accessKeyId: string,
  scope: string,
  signedHeaders: string,
  signature: string
) =>
  `${SIGNING_ALGORITHM} Credential=${accessKeyId}/${scope}, SignedHeaders=${signedHeaders}, Signature=${signature}`;

const parseUrl = (
  url: string
): { host: string; path: string; queryString?: string } => {
  const parsed = new URL(url);
  const { host, search, pathname } = parsed;
  return {
    host,
    path: pathname,
    queryString: (search || '').split('?')[1],
  };
};

// Docs
// https://docs.aws.amazon.com/apigateway/api-reference/signing-requests/

// List of services
// https://docs.aws.amazon.com/general/latest/gr/rande.html

const SERVICE = 'execute-api';

export const sign = (
  request: APIParams,
  credentials: CognitoIdentityCredentials
): APIParams => {
  request.headers = request.headers || {};
  if (request.body) {
    request.headers['Content-Type'] = 'application/json; charset=UTF-8';
  }

  const { accessKeyId, secretAccessKey, sessionToken } = credentials;
  const dt = new Date();
  const dtStr = dt.toISOString().replace(/[:-]|\.\d{3}/g, '');
  const dStr = dtStr.substr(0, 8);

  const { host, path, queryString } = parseUrl(request.url);

  request.headers.Host = host;
  request.headers['X-Amz-Date'] = dtStr;
  request.headers['X-Amz-Security-Token'] = sessionToken;

  // Create Canonical Request
  const canonicalHeaders = createCanoncical(
    request.method,
    path,
    request.headers,
    queryString,
    request.body
  );

  const hashedCanonical = digest(canonicalHeaders);
  const credentialScope = [dStr, REGION, SERVICE, 'aws4_request'].join('/');

  // Create String to sign
  const stringToSign = createStringToSign(
    dtStr,
    credentialScope,
    hashedCanonical
  );

  // create signature
  const signature = createSignature(
    secretAccessKey,
    dStr,
    SERVICE,
    stringToSign
  );

  // set header
  request.headers.Authorization = createAuthorizationHeader(
    accessKeyId,
    credentialScope,
    formatSignedHeaders(request.headers),
    signature
  );

  return request;
};
