import React, { useState, useEffect } from 'react';
import { useFormik, FormikErrors } from 'formik';
import Header from '../components/Header';
import PageStyle from '../components/PageStyle';
import Callout from '../components/Callout';
import { UserStore } from '../stores/User';
import { Link, Redirect } from 'react-router-dom';
import { verifyEmail, resendEmailVerfication } from '../services/cognito/core';
import { CognitoUser } from 'amazon-cognito-identity-js';
import Loader from '../components/Loader';

interface VerifyField {
  code: string;
}

const VerifyEmail: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const {
    userData,
    cognitoUser,
    updateUser,
    isOauth,
  } = UserStore.useContainer();

  const resendVerfication = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    setIsLoading(true);
    const user = cognitoUser as CognitoUser;
    resendEmailVerfication(user)
      .catch((error) => setError(error.message))
      .then(() => setIsLoading(false));
  };

  const formik = useFormik({
    initialValues: {
      code: '',
    },
    validate: (values) => {
      const e: FormikErrors<VerifyField> = {};
      const { code } = values;
      setError('');
      if (!code) {
        e.code = 'Please enter your verification code.';
      }
      return e;
    },
    onSubmit: (values, actions) => {
      setIsLoading(true);
      setError('');
      const { code } = values;
      const user = cognitoUser as CognitoUser;
      verifyEmail(user, code)
        .then(() => updateUser())
        .then(() => {
          setIsSuccess(true);
          actions.resetForm();
        })
        .catch((error) => setError(error.message))
        .then(() => setIsLoading(false));
    },
  });

  useEffect(() => {
    if (userData?.email_verified === 'true') setIsSuccess(true);
    if (userData?.email_verified === 'false') setIsSuccess(false);
  }, [userData]);

  const { code } = formik.values;
  const { errors } = formik;

  const errorMessage = Object.values(errors)[0] || error || '';

  if (isOauth()) return <Redirect to="/settings/" />;

  return (
    <>
      <Header title="Verify Email" />
      <PageStyle>
        {isSuccess ? (
          <Callout status="success" style={{ margin: '1rem 0' }}>
            <h4>Success</h4>
            <p>You have successfully verfied your email.</p>
          </Callout>
        ) : (
          <>
            <Callout status="info" style={{ margin: '1rem 0' }}>
              <h4>Verifying your email</h4>
              <p>
                We sent a verification code to <b>{userData?.email}</b>.{' '}
                <Link to="/" onClick={resendVerfication}>
                  Click here
                </Link>{' '}
                to resend the verfication code.
              </p>
            </Callout>
            {errorMessage && (
              <Callout status="error" style={{ marginBottom: '1rem' }}>
                <h4>Error</h4>
                <p>{errorMessage}</p>
              </Callout>
            )}
            <form onSubmit={formik.handleSubmit} autoComplete="off">
              {!isLoading && (
                <label>
                  Verification Code
                  <input
                    name="code"
                    id="code"
                    type="text"
                    autoComplete="new-password"
                    onChange={formik.handleChange}
                    value={code}
                    placeholder="Verification Code"
                    style={{ marginBottom: '1rem' }}
                  />
                </label>
              )}
              <button type="submit" className="button-wide">
                {isLoading ? <Loader /> : <span>Verify Email</span>}
              </button>
            </form>
          </>
        )}
      </PageStyle>
    </>
  );
};

export default VerifyEmail;
