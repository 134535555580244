import { get } from '../../services/api';
import { env } from '../../utils/env';

const ENDPOINT = env('REACT_APP_API_ENDPOINT');

export const getCustomData = (
  sub: string,
  email: string,
  opts?: PromiseOptions
): Promise<CustomData> =>
  new Promise((resolve, reject) => {
    const url = `${ENDPOINT}${env('REACT_APP_ENDPOINT_GET_USER_INFO')}`;
    const queryString = { sub, email };
    get<CustomData>({ url, queryString }, opts)
      .then(resolve)
      .catch((error) => reject(error.messgae));
  });
