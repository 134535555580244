import { useRef, useEffect } from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { UserStore } from '../stores/User';

interface Actions {
  onLogin?: PromFunc;
  onLogout?: PromFunc;
  onUpdateUser?: PromFunc;
  onCredentialLoaded?: PromFunc;
  onDetectUser?: PromFunc;
  onDetectNoUser?: PromFunc;
}

export const useAuthEvents = (actions: Actions = {}) => {
  const { isLoaded, customData, cognitoUser } = UserStore.useContainer();
  const prevData = useRef<CustomData | undefined>(customData);
  const prevUser = useRef<CognitoUser | undefined>(cognitoUser);
  const {
    onLogin,
    onLogout,
    onCredentialLoaded,
    onUpdateUser,
    onDetectUser,
    onDetectNoUser,
  } = actions;

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (isLoaded) {
      if (customData && !prevData.current) {
        prevData.current = customData;
        onLogin &&
          onLogin({
            signal,
          });
      } else if (!customData && prevData.current) {
        prevData.current = undefined;
        onLogout &&
          onLogout({
            signal,
          });
      } else if (customData && prevData.current) {
        onUpdateUser &&
          onUpdateUser({
            signal,
          });
      }
    }
    return () => controller.abort();
  }, [isLoaded, customData]); // eslint-disable react-hooks/exhaustive-deps

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    isLoaded && onCredentialLoaded && onCredentialLoaded({ signal });
    return () => controller.abort();
  }, [isLoaded]); // eslint-disable react-hooks/exhaustive-deps

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (cognitoUser && !prevUser.current) {
      prevUser.current = cognitoUser;
      onDetectUser &&
        onDetectUser({
          signal,
        });
    } else if (!cognitoUser && !prevUser.current) {
      onDetectNoUser &&
        onDetectNoUser({
          signal,
        });
    } else if (!cognitoUser && prevUser.current) {
      prevUser.current = undefined;
    }
    return () => controller.abort();
  }, [cognitoUser]); // eslint-disable react-hooks/exhaustive-deps
};
