import { env } from '../../../utils/env';
import { get } from '../../../services/api';

const ENDPOINT = env('REACT_APP_API_ENDPOINT');

const parseRawDiscover = (raw: RawDiscoverItem): DiscoverItem => ({
  index: raw.index,
  title: raw.title,
  subtitle: raw.subtitle,
  imgUrl: raw.url,
  onClick: raw.onClick,
});

export const getDiscoverCards = (
  opts?: PromiseOptions
): Promise<DiscoverItem[]> =>
  new Promise((resolve, reject) => {
    const url = `${ENDPOINT}${env('REACT_APP_ENDPOINT_GET_DISCOVER_CARDS')}`;
    get<RawDiscoverItem[]>({ url }, opts)
      .then((rawDiscover) => resolve(rawDiscover.map(parseRawDiscover)))
      .catch((error) => reject(error.messgae));
  });
