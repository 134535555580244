import AWS from 'aws-sdk/global';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { env } from '../../../utils/env';

const IDENTITY_POOL_ID = env('REACT_APP_IDENTITY_POOL_ID');
const REGION = env('REACT_APP_REGION');
const USERPOOL_ID = env('REACT_APP_USERPOOL_ID');

AWS.config.correctClockSkew = true;

class CredentialStore {
  private credentials: AWS.CognitoIdentityCredentials;
  constructor() {
    this.credentials = this.createCredentials();
  }

  public createCredentials = (session?: CognitoUserSession) => {
    const Logins = session && {
      [`cognito-idp.${REGION}.amazonaws.com/${USERPOOL_ID}`]: session
        .getIdToken()
        .getJwtToken(),
    };

    const credentials = new AWS.CognitoIdentityCredentials(
      {
        IdentityPoolId: IDENTITY_POOL_ID,
        Logins,
      },
      {
        region: REGION,
      }
    );
    AWS.config.update({ credentials });
    return credentials;
  };

  public initialize = (session?: CognitoUserSession) => {
    this.clear();
    this.credentials = this.createCredentials(session);
    AWS.config.update({ credentials: this.credentials });
  };

  public get = (): Promise<AWS.CognitoIdentityCredentials> =>
    new Promise((resolve, reject) => {
      this.credentials
        .getPromise()
        .then(() => {
          const credentials = this.credentials;
          AWS.config.update({ credentials });
          resolve(AWS.config.credentials as AWS.CognitoIdentityCredentials);
        })
        .catch(reject);
    });

  public clear = () => {
    this.credentials.clearCachedId();
    AWS.config.credentials = undefined;
  };
}

export const Credentials = new CredentialStore();

const STAGE = env('REACT_APP_STAGE');

if (STAGE === 'DEVELOPMENT') {
  (window as any).Credentials = Credentials; // eslint-disable-line
}
