import React from 'react';
import './Sidebar.css';

interface Props {
  SidebarContent: React.FC;
  sidebarContentWidth: number;
  isDocked: boolean;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const Sidebar: React.FC<Props> = ({
  SidebarContent,
  sidebarContentWidth: width,
  isDocked,
  isOpen,
  setIsOpen,
  children,
}) => {
  const transform =
    !isDocked && !isOpen ? 'translateX(-100%)' : 'translateX(0)';

  const zIndex = isOpen && !isDocked ? 999 : -1;
  const opacity = isOpen && !isDocked ? 1 : 0;
  const paddingLeft = isDocked ? width : 0;

  return (
    <>
      <div
        className="sidebar-overlay"
        onClick={() => setIsOpen(false)}
        style={{ zIndex, opacity }}
      />
      <div className="sidebar-menu" style={{ transform, width }}>
        <SidebarContent />
      </div>

      <div className="sidebar-content" style={{ paddingLeft }}>
        {children}
      </div>
    </>
  );
};

export default Sidebar;
