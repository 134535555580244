import { env } from '../../utils/env';
import { post } from '../../services/api';

const ENDPOINT = env('REACT_APP_API_ENDPOINT');

interface ValidateParams {
  sub: string;
  code: string;
}

export const validateDiscount = (
  { sub, code }: ValidateParams,
  opts?: PromiseOptions
) => {
  const url = `${ENDPOINT}${env('REACT_APP_ENDPOINT_POST_VALIDATE_COUPON')}`;
  const body = JSON.stringify({
    sub,
    coupon: code,
  });
  return post({ url, body }, opts);
};

interface ApplyParams {
  sub: string;
  customerID: string;
  code: string;
}

export const applyDiscount = (
  { sub, customerID, code }: ApplyParams,
  opts?: PromiseOptions
) => {
  const url = `${ENDPOINT}${env('REACT_APP_ENDPOINT_POST_APPLY_COUPON')}`;
  const body = JSON.stringify({
    sub,
    customerID,
    coupon: code,
  });
  return post({ url, body }, opts);
};
