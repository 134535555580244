import React from 'react';
import ProductCard from '../ProductCard';
import './ProductViewer.css';

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  products: Product[];
  isDataHidden?: boolean;
}

const ProductViewer: React.FC<Props> = ({
  products,
  isDataHidden,
  ...divProps
}) => {
  return (
    <div {...divProps}>
      <div className="row product-viewer">
        {products.map((product, idx) => (
          <div className="col-xs-6 col-md-4 col-lg-3" key={product.asin}>
            <ProductCard
              {...product}
              rank={idx + 1}
              isDataHidden={isDataHidden}
              style={{ margin: '1rem', marginBottom: '2rem' }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductViewer;
