import React from 'react';
import { Link } from 'react-router-dom';
import Row from '../Row';
import './NicheCard.css';

type DivProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

interface Props extends DivProps {
  isDataHidden?: boolean;
  isLinkHidden?: boolean;
}

const NicheCard: React.FC<Niche & Props> = ({
  isLinkHidden,
  isDataHidden,
  listings,
  rank,
  averageSalesRank,
  bestSalesRank,
  percentSalesRanks,
  ...divProps
}) => {
  return (
    <div className="nichecard-container" {...divProps}>
      <div className="nichecard ">
        <div className="row">
          <div className="col-xs-12">
            <div className="row">
              <div className="col-xs-6">
                <img src={listings[0].imgUrl} className=" nichecard-img" />
              </div>
              <div className="col-xs-6">
                <img src={listings[1].imgUrl} className=" nichecard-img" />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-6">
                <img src={listings[2].imgUrl} className=" nichecard-img" />
              </div>
              <div className="col-xs-6">
                <img src={listings[3].imgUrl} className=" nichecard-img" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isDataHidden && (
        <div>
          {!isLinkHidden && (
            <Row>
              <span className="nichecard-rank">{rank + 1}.</span>

              <Link
                to={`/niches/${rank + 1}/`}
                className="button button button-small"
              >
                View Niche
              </Link>
            </Row>
          )}
          <Row className="nichecard-data-row">
            <span>Average Sales Rank</span>
            <span>{averageSalesRank.toLocaleString()}</span>
          </Row>
          <Row className="nichecard-data-row">
            <span>Best Sales Rank</span>
            <span>{bestSalesRank.toLocaleString()}</span>
          </Row>
          <Row className="nichecard-data-row">
            <span>Percent Sold</span>
            <span>{Math.round(percentSalesRanks * 100)}%</span>
          </Row>
        </div>
      )}
    </div>
  );
};

export default NicheCard;
