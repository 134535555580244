import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import './MenuItem.css';
import { Link, LinkProps } from 'react-router-dom';

interface Props extends LinkProps {
  title: string;
}

const MenuItem: React.FC<Props> = ({ title, ...props }) => {
  const { className } = props;
  const classNames: string[] = [
    className || '',
    'button',
    'button-gray',
    'menu-item',
  ];

  // if (isSelected) classNames.push('button-gray-select');

  return (
    <Link className={classNames.join(' ')} {...props}>
      <span>{title}</span>
      <FontAwesomeIcon icon={faChevronRight} />
    </Link>
  );
};

export default MenuItem;
