import React from 'react';
import { ShirtsRisingNTM } from '../stores/Data/Products';
import Header from '../components/Header';
import { NichesStore } from '../stores/Data/Niches';
import { Link, Redirect } from 'react-router-dom';
import PageStyle from '../components/PageStyle';
import { UserStore } from '../stores/User';
import Row from '../components/Row';
import ProductViewer from '../components/ProductViewer';
import Loader from '../components/Loader';
import NicheViewer from '../components/NicheViewer';

interface Props {
  to: string;
  text: string;
}
const RightButton: React.FC<Props> = ({ to, text }) => (
  <Link to={to} className="button button-small">
    {text}
  </Link>
);

const Home: React.FC = () => {
  const { userData, isOauth } = UserStore.useContainer();
  if (!isOauth() && userData?.email_verified === 'false') {
    return <Redirect to="/settings/email/verify/" />;
  }

  const {
    products: shirts,
    isLoading: isLoadingShirts,
  } = ShirtsRisingNTM.useContainer();

  const { niches, isLoading: isloadingNiches } = NichesStore.useContainer();

  const isLoading = isLoadingShirts || isloadingNiches;

  return (
    <>
      <Header title="Home" titleRoute="/" />
      <PageStyle style={{ marginTop: '1em' }}>
        <Row style={{ marginTop: '2em' }}>
          <h1>Top Niches</h1>
          <RightButton to="/niches/" text="See More" />
        </Row>
        <NicheViewer niches={niches.slice(0, 6)} />
      </PageStyle>
      <PageStyle style={{ marginTop: '4em' }}>
        <Row style={{ marginTop: '2em' }}>
          <h1>Rising Shirts</h1>
          <RightButton to="/shirts/" text="See More" />
        </Row>
        <ProductViewer products={shirts.slice(0, 12)} />
      </PageStyle>
      {isLoading && <Loader />}
    </>
  );
};

export default Home;
