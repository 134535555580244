import React from 'react';
import Header from '../components/Header';
import PageStyle from '../components/PageStyle';
import MenuItem from '../components/MenuItem';
import { UserStore } from '../stores/User';
import { Redirect, Link } from 'react-router-dom';
import Callout from '../components/Callout';
import Divider from '../components/Divider';

const Settings: React.FC = () => {
  const { customData, isOauth, userData } = UserStore.useContainer();
  if (!customData) return <Redirect to="/logout/" />;
  const { customer } = customData;
  const { subscriptions, sources } = customer;
  const { data: subscriptionData } = subscriptions;
  const subscription = (subscriptionData || [0])[0];
  const { data: sourceData } = sources;
  const card = (sourceData || [])[0];
  const isExpired = !subscription;
  const isTrial = subscription && subscription.status === 'trialing';

  return (
    <>
      <Header title={'Settings'} />
      <PageStyle>
        {isExpired && (
          <>
            <Callout status="info" style={{ margin: '1rem 0' }}>
              <h4> No Subscription</h4>
              <p style={{ margin: 0 }}>
                A subscription is only $10 a month, our data is updated daily
                and you can cancel whenever.
              </p>
            </Callout>
            <Link to="/settings/subscription/" className="button button-wide">
              Start Subscription
            </Link>
            <Divider />
          </>
        )}
        {isTrial && (
          <>
            <Callout status="info" style={{ margin: '1rem 0' }}>
              <h4>Find March Data Useful?</h4>
              <p style={{ margin: 0 }}>
                A subscription is only $10 a month, our data is updated daily,
                and you can cancel whenever.
              </p>
            </Callout>
            <Link to="/settings/subscription/" className="button button-wide">
              Start Subscription
            </Link>
            <Divider />
          </>
        )}
        {!(isTrial || isExpired) && (
          <MenuItem title="Subscription" to="/settings/subscription/" />
        )}
        {card && <MenuItem title="Payment" to="/settings/payment/" />}
        <MenuItem title="Discounts" to="/settings/discounts/" />
        <Divider style={{ margin: '1.5rem 0' }} />
        {!isOauth() && (
          <>
            <MenuItem title="Email" to="/settings/email/" />
            {userData?.email_verified === 'false' && (
              <MenuItem title="Verify Email" to="/settings/email/verify" />
            )}
            <MenuItem title="Password" to="/settings/password/" />
            <Divider style={{ margin: '1.5rem 0' }} />
          </>
        )}
        <MenuItem title="Contact" to="/contact/" />
        <MenuItem title="Logout" to="/logout/" />
      </PageStyle>
    </>
  );
};

export default Settings;
