import React from 'react';
import './Divider.css';

type DivProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const Divider: React.FC<DivProps> = ({ className, ...props }) => {
  const classList = ['divider', className || ''];
  return <div className={classList.join(' ')} {...props} />;
};

export default Divider;
