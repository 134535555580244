import { createContainer } from 'unstated-next';
import { useState } from 'react';
import { useTimerEvents } from '../../hooks/useTimerEvents';
import {
  PopSocketsFeaturedNTM,
  PopSocketsFeaturedTM,
  PopSocketsRisingNTM,
  PopSocketsRisingTM,
  ShirtsFeaturedNTM,
  ShirtsFeaturedTM,
  ShirtsNewestNTM,
  ShirtsNewestTM,
  ShirtsRisingNTM,
  ShirtsRisingTM,
} from '../../stores/Data/Products';
import { TimerStore } from '../Data/Timer';

const State = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onTimerEnd = () => setIsOpen(true);

  useTimerEvents({ onTimerEnd });

  const {
    reset: resetPopsocketsFeaturedNTM,
  } = PopSocketsFeaturedNTM.useContainer();
  const {
    reset: resetPopsocketsFeaturedTM,
  } = PopSocketsFeaturedTM.useContainer();
  const {
    reset: resetPopsocketsRisingNTM,
  } = PopSocketsRisingNTM.useContainer();
  const { reset: resetPopSocketsRisingTM } = PopSocketsRisingTM.useContainer();
  const { reset: resetShirtsFeaturedNTM } = ShirtsFeaturedNTM.useContainer();
  const { reset: resetShirtsFeaturedTM } = ShirtsFeaturedTM.useContainer();
  const { reset: resetShirtsNewestNTM } = ShirtsNewestNTM.useContainer();
  const { reset: resetShirtsNewestTM } = ShirtsNewestTM.useContainer();
  const { reset: resetShirtsRisingNTM } = ShirtsRisingNTM.useContainer();
  const { reset: resetShirtsRisingTM } = ShirtsRisingTM.useContainer();
  const { reset: resetTimer } = TimerStore.useContainer();

  const reset = () =>
    Promise.all([
      resetPopsocketsFeaturedNTM(),
      resetPopsocketsFeaturedTM(),
      resetPopsocketsRisingNTM(),
      resetPopSocketsRisingTM(),
      resetShirtsFeaturedNTM(),
      resetShirtsFeaturedTM(),
      resetShirtsNewestNTM(),
      resetShirtsNewestTM(),
      resetShirtsRisingNTM(),
      resetShirtsRisingTM(),
      resetTimer(),
    ]);

  return {
    isOpen,
    setIsOpen,
    reset,
  };
};

export const DataModalStore = createContainer(State);
