import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useFormik, FormikErrors } from 'formik';
import { UserStore } from '../stores/User';
import Header from '../components/Header';
import PageStyle from '../components/PageStyle';
import Callout from '../components/Callout';
import { updateUserAttributes } from '../services/cognito/core';
import { CognitoUser } from 'amazon-cognito-identity-js';
import Loader from '../components/Loader';

interface ChangeEmailFields {
  email: string;
}

const ChangeEmail = () => {
  const {
    isOauth,
    userData,
    cognitoUser,
    updateUser,
  } = UserStore.useContainer();

  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const { push } = useHistory();

  const formik = useFormik({
    initialValues: {
      email: userData?.email || '',
    },
    validate: (values) => {
      const e: FormikErrors<ChangeEmailFields> = {};
      const { email } = values;
      setError('');

      if (!email) {
        e.email = 'An email is required';
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        e.email = 'Invalid email address';
      }

      if (email !== '') setIsSuccess(false);
      return e;
    },
    validateOnChange: true,
    onSubmit: (values) => {
      const { email } = values;
      const user = cognitoUser as CognitoUser;
      setIsLoading(true);
      updateUserAttributes(user, { email })
        .then(() => updateUser())
        .then(() => push('/settings/email/verify/'))
        .catch((err) => {
          setError(err.message);
          setIsLoading(false);
        });
    },
  });

  if (isOauth()) {
    return <Redirect to="/settings/" />;
  }

  const { email } = formik.values;
  const { errors } = formik;

  const errorMessage = Object.values(errors)[0] || error || '';

  return (
    <>
      <Header title="Change Email" />
      <PageStyle>
        <Callout status="info" style={{ margin: '1rem 0' }}>
          <h4>Changing your email</h4>
          <p>To change your email, modify your existing and click save.</p>
        </Callout>
        {errorMessage && (
          <Callout status="error" style={{ marginBottom: '1rem' }}>
            <h4>Error</h4>
            <p>{errorMessage}</p>
          </Callout>
        )}
        {isSuccess && (
          <Callout status="success" style={{ marginBottom: '1rem' }}>
            <h4>Success</h4>
            <p>You have successfully changed your email.</p>
          </Callout>
        )}
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <label>
            Email
            <input
              name="email"
              id="email"
              type="email"
              onChange={formik.handleChange}
              value={email}
              placeholder="Old Password"
              style={{ marginBottom: '1rem' }}
            />
          </label>
          <button
            type="submit"
            className="button-wide"
            disabled={email === userData?.email}
          >
            {isLoading ? <Loader /> : <span>Save</span>}
          </button>
        </form>
      </PageStyle>
    </>
  );
};

export default ChangeEmail;
