import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { NichesStore } from '../stores/Data/Niches';
import useScrollToTop from '../hooks/useScrollToTop';
import Header from '../components/Header';
import InfiniteLoader from '../components/InfiniteLoader';
import PageStyle from '../components/PageStyle';
import NicheViewer from '../components/NicheViewer';

const Niches: React.FC = () => {
  useScrollToTop();

  const { getNextPage, hasAll, niches, isLoading } = NichesStore.useContainer();

  return (
    <>
      <Header title="Niches" />
      <InfiniteScroll
        loadMore={() => getNextPage()}
        hasMore={!isLoading && !hasAll}
        useWindow={true}
      >
        <PageStyle>
          <NicheViewer niches={niches} />
          {isLoading && <InfiniteLoader />}
        </PageStyle>
      </InfiniteScroll>
    </>
  );
};

export default Niches;
