import { useRef, useEffect } from 'react';

const useIsMounted = () => {
  const isMountedRef = useRef<boolean>(true);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  });

  return Boolean(isMountedRef.current);
};

export default useIsMounted;
