import React from 'react';
import ReactLoading from 'react-loading';
import { Switch, Route, Redirect } from 'react-router-dom';
import SidebarMenu from '../containers/SidebarMenu';
import Sidebar from '../components/Sidebar';
import {
  PopSocketsFeaturedNTM,
  PopSocketsFeaturedTM,
  ShirtsRisingTM,
  PopSocketsRisingTM,
  ShirtsFeaturedNTM,
  ShirtsFeaturedTM,
  ShirtsNewestNTM,
  ShirtsNewestTM,
} from '../stores/Data/Products';
import { SidebarStore } from '../stores/App/Sidebar';
import { UserStore } from '../stores/User';
import { DataModalStore } from '../stores/App/DataModalStore';
import PaidRoute from './PaidRoute';
import Landing from '../pages/Landing';
import Home from '../pages/Home';
import Settings from '../pages/Settings';
import Shirts from '../pages/Shirts';
import Popsockets from '../pages/Popsockets';
import Niches from '../pages/Niches';
import Diagnostics from '../pages/Diagnostics';
import Niche from '../pages/Niche';
import Payment from '../pages/Payment';
import Logout from '../pages/Logout';
import Discounts from '../pages/Discounts';
import DataModal from '../components/DataModal';
import Subscription from '../pages/Subscription';
import Contact from '../pages/Contact';
import Login from '../pages/Login';
import LoginEmail from '../pages/LoginEmail';
import ResetPasswordStart from '../pages/ResetPasswordStart';
import ResetPasswordConfirm from '../pages/ResetPasswordConfirm';
import ChangeEmail from '../pages/ChangeEmail';
import ChangePassword from '../pages/ChangePassword';
import VerifyEmail from '../pages/VerifyEmail';
import About from '../pages/About';

const AuthedSwitch: React.FC = () => {
  const {
    isOpen,
    isDocked,
    setIsDockOpen,
    sidebarWidth,
  } = SidebarStore.useContainer();

  return (
    <PopSocketsFeaturedNTM.Provider>
      <PopSocketsFeaturedTM.Provider>
        <PopSocketsRisingTM.Provider>
          <ShirtsFeaturedNTM.Provider>
            <ShirtsFeaturedTM.Provider>
              <ShirtsNewestNTM.Provider>
                <ShirtsNewestTM.Provider>
                  <ShirtsRisingTM.Provider>
                    <DataModalStore.Provider>
                      <Sidebar
                        SidebarContent={SidebarMenu}
                        sidebarContentWidth={sidebarWidth}
                        isOpen={isOpen}
                        isDocked={isDocked}
                        setIsOpen={setIsDockOpen}
                      >
                        <DataModal />
                        <Switch>
                          <Route path="/" component={Home} exact={true} />
                          <Route
                            path="/diagnostics/"
                            component={Diagnostics}
                            exact={true}
                          />
                          <Route
                            path="/settings/"
                            component={Settings}
                            exact={true}
                          />
                          <Route
                            path="/settings/payment/"
                            component={Payment}
                            exact={true}
                          />
                          <Route
                            path="/settings/discounts/"
                            component={Discounts}
                            exact={true}
                          />
                          <Route
                            path="/settings/subscription/"
                            component={Subscription}
                            exact={true}
                          />
                          <Route
                            path="/settings/email/"
                            component={ChangeEmail}
                            exact={true}
                          />
                          <Route
                            path="/settings/email/verify/"
                            component={VerifyEmail}
                            exact={true}
                          />
                          <Route
                            path="/auth/password/reset/confirm/"
                            component={() => (
                              <Redirect to="/settings/email/verify/" />
                            )}
                            exact={true}
                          />
                          <Route
                            path="/settings/password/"
                            component={ChangePassword}
                            exact={true}
                          />
                          <PaidRoute
                            path="/shirts/"
                            component={Shirts}
                            exact={true}
                          />
                          <PaidRoute
                            path="/niches/:id/"
                            component={Niche}
                            exact={true}
                          />
                          <PaidRoute
                            path="/niches/"
                            component={Niches}
                            exact={true}
                          />
                          <PaidRoute
                            path="/popsockets/"
                            component={Popsockets}
                            exact={true}
                          />
                          <Route
                            path="/logout/"
                            component={Logout}
                            exact={true}
                          />
                          <Route
                            path="/contact/"
                            component={Contact}
                            exact={true}
                          />
                          <Route
                            path="/about/"
                            component={About}
                            exact={true}
                          />
                          <Redirect to="/" />
                        </Switch>
                      </Sidebar>
                    </DataModalStore.Provider>
                  </ShirtsRisingTM.Provider>
                </ShirtsNewestTM.Provider>
              </ShirtsNewestNTM.Provider>
            </ShirtsFeaturedTM.Provider>
          </ShirtsFeaturedNTM.Provider>
        </PopSocketsRisingTM.Provider>
      </PopSocketsFeaturedTM.Provider>
    </PopSocketsFeaturedNTM.Provider>
  );
};

const UnauthedSwitch: React.FC = () => {
  return (
    <Switch>
      <Route path="/" component={Landing} exact={true} />
      <Route path="/contact/" component={Contact} exact={true} />
      <Route path="/auth/login/" component={Login} exact={true} />
      <Route path="/auth/login/email/" component={LoginEmail} exact={true} />
      <Route
        path="/auth/password/reset/start/"
        component={ResetPasswordStart}
        exact={true}
      />
      <Route
        path="/auth/password/reset/confirm/"
        component={ResetPasswordConfirm}
        exact={true}
      />
      <Route path="/about/" component={About} exact={true} />
      <Redirect to="/" />
    </Switch>
  );
};

const Loader: React.FC = () => (
  <div key={0} style={{ height: '100vh', display: 'flex', width: '100%' }}>
    <div style={{ margin: 'auto' }}>
      <ReactLoading type={'spin'} color={'var(--color-primary'} />
    </div>
  </div>
);

const App: React.FC = () => {
  const {
    isLoaded,
    customData,
    userData,
    cognitoUser,
  } = UserStore.useContainer();

  if (isLoaded && !cognitoUser) return <UnauthedSwitch />;
  else if (isLoaded && userData && customData) return <AuthedSwitch />;
  else return <Loader />;
};

export default App;
