import { useState } from 'react';
import { createContainer } from 'unstated-next';
import { getDiscoverCards } from './api';
import { useAuthEvents } from '../../../hooks/useAuthEvents';

const State = () => {
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [items, setItems] = useState<DiscoverItem[]>([]);

  const updateDiscover = (opts?: PromiseOptions) => {
    setIsLoading(true);
    getDiscoverCards(opts)
      .then(setItems)
      .catch((error) => {
        console.log(error);
        if (error) setError(error?.message);
      })
      .then(() => setIsLoading(false));
  };

  useAuthEvents({ onCredentialLoaded: updateDiscover });

  return { error, isLoading, updateDiscover, items };
};

export const DiscoverStore = createContainer(State);
