import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-solid-svg-icons';
import { SidebarStore } from '../../stores/App/Sidebar';
import './Header.css';
import Row from '../Row';
import { UserStore } from '../../stores/User';
import PageStyle from '../PageStyle';
import { Link } from 'react-router-dom';
import useScroll from '../../hooks/useDocumentScroll';
import { useDocumentWidth } from '../../hooks/useDocumentWidth';

const MenuButton: React.FC = () => {
  const { isDocked, setIsDockOpen } = SidebarStore.useContainer();
  const { cognitoUser } = UserStore.useContainer();
  const shouldRender = !isDocked && Boolean(cognitoUser);
  return shouldRender ? (
    <button
      type="button"
      className="button button-icon"
      onClick={() => setIsDockOpen(true)}
    >
      <FontAwesomeIcon icon={faBars} style={{ fontSize: '2rem' }} />
    </button>
  ) : (
    <React.Fragment />
  );
};

interface Props {
  Controls?: React.FC;
  Right?: React.FC;
  title: string;
  isDisabled?: boolean;
  titleRoute?: string;
}

const MINIMUM_SCROLL = 80;
const TIMEOUT_DELAY = 300;

const Header: React.FC<Props> = ({
  title,
  Controls,
  isDisabled,
  Right,
  titleRoute,
}) => {
  const { sidebarWidth, isDocked } = SidebarStore.useContainer();
  const [isHeaderHidden, setIsHeaderHidden] = useState(false);
  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLElement>(null);

  useScroll((callbackData) => {
    const { previousScrollTop, currentScrollTop } = callbackData;
    const isScrolledDown = previousScrollTop < currentScrollTop;
    const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL;
    setTimeout(() => {
      setIsHeaderHidden(isScrolledDown && isMinimumScrolled);
    }, TIMEOUT_DELAY);
  });

  const [width] = useDocumentWidth();

  useEffect(() => {
    const newHeight = ref.current ? ref.current.offsetHeight : 0;
    setHeight(newHeight);
  }, [ref, width]);

  const headerClasses = [
    'header',
    isHeaderHidden && !isDisabled ? 'hidden' : '',
  ];

  const left = isDocked ? sidebarWidth : 0;

  return (
    <>
      <header className={headerClasses.join(' ')} style={{ left }} ref={ref}>
        <PageStyle>
          <Row>
            <Link to={titleRoute || '/'} className="header-title">
              {title}
            </Link>
            {Right ? <Right /> : <MenuButton />}
          </Row>
          {Controls && <Controls />}
        </PageStyle>
      </header>
      <div style={{ height }} />
    </>
  );
};

export default Header;
