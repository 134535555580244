import { formatRawProduct } from '../Products/api';
import { env } from '../../../utils/env';
import { get } from '../../../services/api';

const formatRawNiche = (rawNiche: RawNiche): Niche => ({
  averageSalesRank: Number(rawNiche.average_sales_rank),
  bestSalesRank: Number(rawNiche.best_sales_rank),
  listings: rawNiche.listings.map(formatRawProduct),
  percentSalesRanks: Number(rawNiche.percent_sales_ranks),
  rank: rawNiche.rangeKey,
});

export const getNiches = (
  start: number,
  limit: number,
  headers: Obj,
  opts?: PromiseOptions
): Promise<Niche[]> =>
  new Promise((resolve, reject) => {
    const route = headers.token
      ? env('REACT_APP_ENDPOINT_GET_PAID_NICHES')
      : env('REACT_APP_ENDPOINT_GET_FREE_NICHES');
    const url = `${env('REACT_APP_API_ENDPOINT')}${route}`;

    const queryString: Obj = {
      start: String(start),
      limit: String(limit),
    };
    get<RawNiche[]>({ url, queryString, headers }, opts)
      .then((rawNiches) => rawNiches.map(formatRawNiche))
      .then(resolve)
      .catch(reject);
  });
