import { useState, useEffect } from 'react';
import { throttle } from 'lodash';

interface Params {
  previousScrollTop: number;
  currentScrollTop: number;
}

type ScollCallback = (params: Params) => void;

const useScroll = (callback: ScollCallback) => {
  const [, setScrollPosition] = useState(0);
  let previousScrollTop = 0;
  const handleScroll = () => {
    const { scrollTop: currentScrollTop } =
      document.documentElement || document.body;

    setScrollPosition((previousPosition) => {
      previousScrollTop = previousPosition;
      return currentScrollTop;
    });

    callback({ previousScrollTop, currentScrollTop });
  };

  const throttled = throttle(handleScroll, 250);

  useEffect(() => {
    window.addEventListener('scroll', throttled);
    return () => window.removeEventListener('scroll', throttled);
  }, []);
};

export default useScroll;
