import React, { useState } from 'react';
import ReactLoading from 'react-loading';
import { useFormik, FormikErrors } from 'formik';
import Header from '../../components/Header';
import PageStyle from '../../components/PageStyle';
import { ContactFields, sendContact } from './api';
import Callout from '../../components/Callout';
import { UserStore } from '../../stores/User';
import Row from '../../components/Row';
import { Link } from 'react-router-dom';
import Loader from '../../components/Loader';

const Contact: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const { userData } = UserStore.useContainer();

  const send = (params: ContactFields) =>
    sendContact({ ...params, sub: userData?.sub });

  const formik = useFormik({
    initialValues: {
      email: userData?.email || '',
      name: '',
      subject: '',
      message: '',
    },
    validate: (values) => {
      const e: FormikErrors<ContactFields> = {};
      const { email, name, subject, message } = values;
      if (!email) {
        e.email = 'An email is required';
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        e.email = 'Invalid email address';
      }

      if (!name) {
        e.name = 'A name is required';
      }

      if (!subject) {
        e.subject = 'A subject is required';
      }

      if (!message) {
        e.message = 'A message is required';
      } else if (message.length > 2000) {
        e.message = 'Your message must be less then 2000 characters';
      }

      return e;
    },
    onSubmit: (values, actions) => {
      setIsLoading(true);
      send(values)
        .then(() => {
          setIsSuccess(true);
          actions.resetForm();
        })
        .catch((error) => setError(error))
        .then(() => setIsLoading(false));
    },
  });

  const { email, name, subject, message } = formik.values;
  const { errors } = formik;

  const errorMessage = Object.values(errors)[0] || error || '';

  return (
    <>
      <Header title="Contact" />
      <PageStyle>
        {isSuccess ? (
          <>
            <Callout status="success" style={{ margin: '1rem 0' }}>
              <h4>Success</h4>
              <p>{`We'll try to get back to you as soon as possible.`}</p>
            </Callout>
            <Link
              to="/"
              className="button button-wide"
              style={{ margin: '0.5rem 0' }}
            >
              Return To App
            </Link>
          </>
        ) : (
          <>
            <Callout status="info" style={{ margin: '1rem 0' }}>
              <p>
                We try to respond within 24 hours. If you are having issues
                please be descriptive as possible, so we can help you as quickly
                as possible.
              </p>
            </Callout>
            {errorMessage && (
              <Callout status="error" style={{ marginBottom: '1rem' }}>
                <h4>Error</h4>
                <p>{errorMessage}</p>
              </Callout>
            )}
            <form onSubmit={formik.handleSubmit}>
              <label>
                Email
                <input
                  name="email"
                  id="email"
                  type="email"
                  onChange={formik.handleChange}
                  value={email}
                  placeholder="Email"
                  style={{ marginBottom: '0.75rem' }}
                />
              </label>
              <label>
                Name
                <input
                  name="name"
                  id="name"
                  type="text"
                  onChange={formik.handleChange}
                  value={name}
                  placeholder="Name"
                  style={{ marginBottom: '0.75rem' }}
                />
              </label>

              <label>
                Subject
                <input
                  name="subject"
                  id="subject"
                  type="text"
                  onChange={formik.handleChange}
                  value={subject}
                  placeholder="Subject"
                  style={{ marginBottom: '0.75rem' }}
                />
              </label>
              <label>
                <Row>
                  <span>Messages</span>
                  <span>Characters: ({message.length}/2000)</span>
                </Row>
                <textarea
                  name="message"
                  id="messgae"
                  onChange={formik.handleChange}
                  value={message}
                  rows={15}
                  style={{ marginBottom: '0.75rem' }}
                />
              </label>

              <button
                type="submit"
                className="button-wide"
                disabled={isLoading}
              >
                {isLoading ? <Loader /> : <span>Send</span>}
              </button>
            </form>
          </>
        )}
      </PageStyle>
    </>
  );
};

export default Contact;
