import { useState } from 'react';
import { createContainer } from 'unstated-next';
import { getNiches } from './api';
import { UserStore } from '../../User';
import { useAuthEvents } from '../../../hooks/useAuthEvents';

const State = (itemsPerRequest = 8) => {
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isPaid, setIsPaid] = useState<boolean>(false);
  const [hasAll, setHasAll] = useState<boolean>(false);
  const [niches, setNiches] = useState<Niche[]>([]);

  const { getHeaders } = UserStore.useContainer();

  const getNextPage = (
    oldNiches = [...niches],
    opts?: PromiseOptions
  ): Promise<void> =>
    new Promise((resolve, reject) => {
      const start = oldNiches.length;
      const limit = itemsPerRequest + 1;
      const headers = getHeaders();
      setIsLoading(true);
      getNiches(start, limit, headers, opts)
        .then((newNiches) => {
          if (isPaid && newNiches.length < limit) setHasAll(true);
          const spliced = newNiches.splice(0, itemsPerRequest);
          setNiches(oldNiches.concat(spliced));
          setIsLoading(false);
          resolve();
        })
        .catch((error) => {
          setError(error.message);
          reject(error.message);
        });
    });

  const reset = (opts?: PromiseOptions): Promise<void> =>
    new Promise((resolve, reject) => {
      setHasAll(false);
      setNiches([]);
      getNextPage([], opts).then(resolve).catch(reject);
    });

  const onUpdateUser = (opts: PromiseOptions): Promise<void> =>
    new Promise((resolve, reject) => {
      opts.signal.addEventListener('abort', reject);
      const headers = getHeaders();
      if (headers.token) setIsPaid(true);
      resolve();
    });

  useAuthEvents({
    onCredentialLoaded: (opts) => getNextPage([], opts),
    onLogout: reset,
    onLogin: onUpdateUser,
    onUpdateUser,
  });

  return {
    getNextPage,
    reset,
    isLoading,
    hasAll: hasAll || !isPaid,
    niches,
    error,
  };
};

export const NichesStore = createContainer(State);
