import React from 'react';
import { UserStore } from '../stores/User';
import { Redirect } from 'react-router-dom';

const Logout: React.FC = () => {
  const { logout } = UserStore.useContainer();
  logout();
  return <Redirect to="/" />;
};

export default Logout;
