import { useEffect } from 'react';

const scrollToTop = () => window.scrollTo(0, 0);

const useScrollToTop = () => {
  useEffect(() => {
    scrollToTop();
  }, []);

  return { scrollToTop };
};

export default useScrollToTop;
