import React, { useState } from 'react';
import ReactLoading from 'react-loading';
import AuthModal from '../../components/AuthModal';
import { useFormik, FormikErrors } from 'formik';
import Callout from '../../components/Callout';
import {
  createCognitoUser,
  forgotPassword,
  resendEmailVerfication,
} from '../../services/cognito/core';
import { useHistory, Link } from 'react-router-dom';
import Divider from '../../components/Divider';

const Loader = () => (
  <div className="row center-xs ">
    <ReactLoading type={'spin'} color={'white'} height={24} width={24} />
  </div>
);

interface ResetField {
  email: string;
}

const ResetPasswordStart: React.FC = () => {
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { push } = useHistory();
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validate: (values) => {
      const e: FormikErrors<ResetField> = {};
      const { email } = values;
      setError('');
      if (!email) {
        e.email = 'An email is required';
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        e.email = 'Invalid email address';
      }
      return e;
    },
    onSubmit: (values) => {
      const { email } = values;
      setIsLoading(true);
      const user = createCognitoUser(email);
      forgotPassword(user)
        .then(() =>
          push({
            pathname: '/auth/password/reset/confirm/',
            state: {
              email,
            },
          })
        )
        .catch((error) => {
          console.log(error);
          if (error.code === 'InvalidParameterException') {
            setError('Please contact customer service.');
          } else if (error.code === 'UserNotFoundException') {
            setError("That user doesn't exist.");
          } else setError(error.message);
          setIsLoading(false);
        });
    },
  });

  const { email } = formik.values;
  const { errors } = formik;

  const errorMessage = Object.values(errors)[0] || error || '';
  return (
    <AuthModal>
      <div
        className="row center-xs"
        style={{ margin: 0, marginBottom: '1rem' }}
      >
        <h2>Password Reset</h2>
      </div>
      <div
        className="row center-xs"
        style={{ margin: 0, marginBottom: '2rem' }}
      >
        <p style={{ margin: 0 }}>
          To reset your password we need to confirm your identity. Please enter
          your email and we will send you a confirmation link.
        </p>
      </div>
      {errorMessage && (
        <Callout status="error" style={{ marginBottom: '1rem' }}>
          <h4>Error</h4>
          <p>{errorMessage}</p>
        </Callout>
      )}
      <form onSubmit={formik.handleSubmit}>
        <label>
          Email
          <input
            name="email"
            id="email"
            type="email"
            onChange={formik.handleChange}
            value={email}
            placeholder="Email"
            style={{ marginBottom: '1rem' }}
          />
        </label>
        <button type="submit" className="button-wide">
          {isLoading ? <Loader /> : <span>Send Reset Link</span>}
        </button>
      </form>
      <Divider />
      <div className="row center-xs" style={{ margin: 0, marginTop: '1rem' }}>
        <Link to="/auth/login/">Return to Login</Link>
      </div>
    </AuthModal>
  );
};

export default ResetPasswordStart;
