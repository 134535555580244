import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import {
  PopSocketsRisingNTM,
  PopSocketsRisingTM,
  PopSocketsFeaturedNTM,
  PopSocketsFeaturedTM,
} from '../stores/Data/Products';
import ProductViewer from '../components/ProductViewer';
import Header from '../components/Header';
import Controls, { OptionType } from '../components/Controls';
import useScrollToTop from '../hooks/useScrollToTop';
import InfiniteLoader from '../components/InfiniteLoader';
import PageStyle from '../components/PageStyle';

const Popsockets: React.FC = () => {
  const options = [
    { value: 'rising', label: 'Rising' },
    { value: 'featured', label: 'Best Selling' },
  ];

  const [isTrademarked, setIsTrademarked] = useState<boolean>(false);
  const [option, setOption] = useState<OptionType>(options[0]);

  const { scrollToTop } = useScrollToTop();
  useEffect(() => {
    scrollToTop();
  }, [isTrademarked, option, scrollToTop]);

  let container = PopSocketsRisingNTM.useContainer();

  switch (option.value) {
    case 'rising': {
      container = isTrademarked
        ? PopSocketsRisingTM.useContainer()
        : PopSocketsRisingNTM.useContainer();
      break;
    }
    case 'featured': {
      container = isTrademarked
        ? PopSocketsFeaturedTM.useContainer()
        : PopSocketsFeaturedNTM.useContainer();
      break;
    }
  }

  const { getNextPage, hasAll, products, isLoading } = container;

  const RenderControls: React.FC = () => {
    return (
      <Controls
        options={options}
        selectValue={option}
        onChangeSelect={setOption}
        switchValue={isTrademarked}
        onChangeSwitch={setIsTrademarked}
      />
    );
  };

  return (
    <>
      <Header title="PopSockets" Controls={RenderControls} />
      <InfiniteScroll
        initialLoad={false}
        loadMore={() => getNextPage()}
        hasMore={!isLoading && !hasAll}
        useWindow={true}
      >
        <PageStyle>
          <ProductViewer products={products} />
          {isLoading && <InfiniteLoader />}
        </PageStyle>
      </InfiniteScroll>
    </>
  );
};

export default Popsockets;
