import React from 'react';
import Switch from 'react-switch';
import Select from 'react-select';
import './Controls.css';
import Row from '../Row';

export type OptionType = {
  label: string;
  value: string;
};

export type onChangeSwitch = (checked: boolean) => void;

export type onChangeSelect = (value: OptionType) => void;

interface Props {
  options: OptionType[];
  selectValue: OptionType;
  onChangeSelect: onChangeSelect;
  switchValue: boolean;
  onChangeSwitch: onChangeSwitch;
}

const Controls: React.FC<Props> = ({
  options,
  selectValue,
  onChangeSelect,
  switchValue,
  onChangeSwitch,
}) => {
  return (
    <div className="controller-container">
      <div className="row top-xs ">
        <div className="col-xs-12 col-sm-6 controller-col">
          <Select
            options={options}
            value={selectValue}
            onChange={(value) => onChangeSelect(value as OptionType)}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                border: 'none',
                text: 'black',
                primary25: '	#E8E8E8',
                primary: 'var(--color-primary)',
              },
            })}
            styles={{
              control: (base) => ({
                ...base,
                fontWeight: 500,
                boxShadow: 'none',
                borderStyle: 'none',
                borderBlockColor: 'var(--color-grey)',
                backgroundColor: '	#E8E8E8',
                height: '4rem',
                borderRadius: '1rem',
              }),
              menu: (base) => ({
                ...base,
                fontWeight: 500,
                borderRadius: '1rem',
                pading: 'none',
              }),
              dropdownIndicator: (base) => ({
                ...base,
                color: 'var(--color-icon-gray)',
              }),
            }}
          />
        </div>
        <div className="col-xs-12 col-sm-6 controller-col">
          <Row className="controller-switch-container">
            <span>View Trademarked</span>
            <Switch
              onColor="#0c8150"
              offColor="#b0b0b0"
              onChange={(checked, event) => {
                event.preventDefault();
                onChangeSwitch(checked);
              }}
              checked={switchValue}
              handleDiameter={16}
              width={50}
            />
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Controls;
